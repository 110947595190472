import React from 'react';
import createIconElement from './createIconElement';
import { ICON_SIZE_L, ICON_SIZE_LG, ICON_SIZE_MD, ICON_SIZE_SM } from '../../../constants/iconSizesContants';
import { ICON_STATE_DEFAULT, ICON_STATE_PRIMARY } from '../../../constants/iconStatesContants';
import styles from './iconElement.module.css';
import './injectIcons';
var IconElement = createIconElement({
  styles: styles,
  textIcons: {}
});
IconElement.defaultProps = {
  size: ICON_SIZE_MD,
  sate: ICON_STATE_PRIMARY,
  state: null,
  className: null
};
export default IconElement;