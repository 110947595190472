// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import compose from 'recompose/compose'
import { connect, } from 'react-redux'
import RulesContentComponent from 'auction.core/components/RulesContentComponent'
import { injectIntl, } from 'react-intl'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
export default compose(
	connect(
		(state,) => ({
			contacts: state.contacts,
		}),
	),
)(injectIntl(({
		 contacts,
	   intl,
	 }) => {
	return (
		<>
			<RulesContentComponent
				title={intl.messages['rules.title']}
				chapter={intl.messages['rules.desc']}
				rules={(intl.locale === 'ru' ? contacts.rules : contacts.rulesEng) || ''}
			/>
		</>
	)
}))
