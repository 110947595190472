import {
	createNotification,
	NOTIFICATION_TYPE_WARNING,
	NOTIFICATION_TYPE_SUCCESS,
	NOTIFICATION_TYPE_INFO,
} from 'react-redux-notify'
import notification from '../assets/notification'

// eslint-disable-next-line consistent-return
export const sendNotifyError = (message,) => async (dispatch,) => {
	dispatch(createNotification({
		message,
		type: NOTIFICATION_TYPE_WARNING,
		duration: 2000,
		canDismiss: true,
		customStyles: notification,
	}))
}

export const sendNotifySuccess = (message,) => async (dispatch,) => {
	dispatch(createNotification({
		message,
		type: NOTIFICATION_TYPE_SUCCESS,
		duration: 2000,
		canDismiss: true,
		customStyles: notification,
	}))
}
export const sendNotifyInfo = (message,) => async (dispatch,) => {
	dispatch(createNotification({
		message,
		type: NOTIFICATION_TYPE_INFO,
		duration: 2000,
		canDismiss: true,
		customStyles: notification,
	}))
}
