export const defaultLocale = 'ru'
export const localeOptions = [
	{ id: 'en', name: 'English', direction: 'ltr', },
	{ id: 'ru', name: 'Russian', direction: 'ltr', },
]

export const SettingsTypes = {
	UPDATE_SETTINGS: 'UPDATE_SETTINGS',
	CHANGE_LOCALE: 'CHANGE_LOCALE',
}

export const addSettings = (settings,) => ({
	type: SettingsTypes.UPDATE_SETTINGS,
	data: settings.reduce((acc, item) => ({ ...acc, [item.name]: item.value, }), {}),
})

export const changeLocale = (locale) => {
	localStorage.setItem('currentLanguage', locale)
	return (
		{
			type: SettingsTypes.CHANGE_LOCALE,
			payload: locale,
		}
	)
}

const defaultSettings = {
	locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale,
}

export default function settingsReducer (state = defaultSettings, action,) {
	switch (action.type) {
	case SettingsTypes.UPDATE_SETTINGS:
		return {
			...state,
			...action.data,
		}
	case SettingsTypes.CHANGE_LOCALE:
		return { ...state, locale: action.payload, }

	default: return state
	}
}
