import browser from '../../../helpers/detectBrowser';
export var loaded = false;
export var EVENT_ICON_LOADED = 'icons:loaded';

var executeIfLoaded = function executeIfLoaded() {
  return new Promise(function (resolve) {
    if (document.readyState !== ['loading']) {
      resolve();
    }

    window.addEventListener('load', resolve);
  });
};

var getIconLoader = function getIconLoader(iconPackImport) {
  if (browser.isIE) {
    return Promise.all([import(
    /* webpackChunkName: 'icons_ie' */
    'innersvg-polyfill'), iconPackImport()]);
  }

  return iconPackImport();
};

export default (function (iconPackImport) {
  return executeIfLoaded().then(function () {
    return getIconLoader(iconPackImport);
  }).then(function () {
    loaded = true;
  }).catch(console.log);
});