import { gql } from 'apollo-boost'

export const ALL_AUCTIONS_QUERY = gql`
	query GetAuction($from: Int!, $size: Int!) {
		auctionsWithoutArchive(pager: {from: $from, size: $size}) {
			auctions{
				id,
				name,
				description,
				nameEng,
				descriptionEng,
				background,
				pdfLink,
				logo,
			}
			totalCount
		}
	}
`

export const ALL_AUCTIONS_ARCHIVE_QUERY = gql`
	query GetAuction($from: Int!, $size: Int!) {
		auctionsArchive(pager: {from: $from, size: $size}) {
			auctions{
				id,
				name,
				description,
				nameEng,
				descriptionEng,
				background,
				pdfLink,
				logo,
			}
			totalCount
		}
	}
`

export const AUCTIONS_QUERY = gql`
	query GetAuction($auctionId: Int!, $from: Int!, $size: Int!, $order_by: ItemsSortBy, $categoryId: Int) {
		auction(auctionId: $auctionId) {
			id,
			name,
			description,
			nameEng,
			descriptionEng,
			background,
			pdfLink,
			categoryItems {
				id,
				value,
			},
			items(pager: {from: $from, size: $size}, order_by: $order_by, categoryId: $categoryId) {
				totalCount,
				items {
					id,
					auctionId,
					lot,
					name,
					description,
					nameEng,
					descriptionEng,
					status,
					gallery,
					started_price,
					ended_price,
					maxBit {
						auctionId
						userId
						itemId
						rate
						isOutBid
					}
				}
			}
		}
	}
`
export const USER_LOTS_QUERY = gql`
	query GetItems($search: String!, $from: Int!, $size: Int!) {
		content: userItems(input: $search, pager: {from: $from, size: $size}) {
			totalCount,
			items {
				id,
				auctionId,
				lot,
				name,
				description,
				nameEng,
				descriptionEng,
				status,
				gallery,
				started_price,
				ended_price,
				maxRate,
				maxBit {
					auctionId
					userId
					itemId
					rate
					isOutBid
				}
			}
		}
	}
`
export const USER_FAVORITES_QUERY = gql`
	query userFavorites($search: String!, $from: Int!, $size: Int!) {
		content: userFavorites(input: $search, pager: {from: $from, size: $size}) {
			totalCount,
			items {
				id,
				auctionId,
				lot,
				name,
				description,
				nameEng,
				descriptionEng,
				status,
				gallery,
				started_price,
				ended_price,
				maxBit {
					auctionId
					userId
					itemId
					rate
					isOutBid
				}
			}
		}
	}
`

export const ITEM_QUERY = gql`
	query GetItems($item_id: Int!) {
		item(itemId: $item_id) {
			id,
			auctionId,
			lot,
			name,
			description,
			nameEng,
			descriptionEng,
			status,
			gallery,
			started_price,
			ended_price,
			user_price
			isFavorite,
			maxRate,
			maxBit {
				auctionId
				userId
				itemId
				rate
				isOutBid
			}
			steps {
				id
				sum
				step
			}
		}
	}
`

export const ITEM_QUERY_SEARCH = gql`
	query GetItems($search: String!, $offset: Int, $limit: Int) {
		search(input: $search, offset: $offset, limit: $limit) {
			totalCount,
			items {
				id,
				auctionId,
				lot,
				name,
				description,
				nameEng,
				descriptionEng,
				status,
				gallery,
				started_price,
				ended_price,
			}
		}
	}
`

export const ADD_ITEM_PRICE_QUERY = gql`
	mutation SetItemPrice($auctionId: Int!, $itemId: Int!, $price: Int!) {
		setLotPrice(auctionId: $auctionId, itemId: $itemId, price: $price) {
			status
		}
	}
`
export const SET_LOT_FAVORITE_QUERY = gql`
	mutation setFavorite($auctionId: Int!, $itemId: Int!, $isFavorite: Boolean!) {
		setFavorite(auctionId: $auctionId, itemId: $itemId, isFavorite: $isFavorite) {
			status
		}
	}
`

export const ADD_MESSAGE_TO_CHAT = gql`
	mutation AddMessageToChat($auctionId: Int!, $text: String!) {
		addMessageToChat(auctionId: $auctionId, text: $text) {
			status
		}
	}
`
export const SEND_PING = gql`
	mutation SetPingClient($auctionId: Int!, $timestamp: Int!) {
		setPingClient(auctionId: $auctionId, timestamp: $timestamp) {
			status
		}
	}
`

export const ACTIVE_QUERY = gql`
	query GetActiveQuery($auctionId: Int!) {
		active: getActiveAuction(auctionId: $auctionId) {
			auction {
				id,
				name,
				description,
				nameEng,
				descriptionEng,
				pdfLink,
				startedAt,
				steps {
					sum
					step
				},
				categoryItems {
					id,
					value,
				},
				logo,
				background,
				isStarted,
				isFinished,
				currency
			}

			reOpenStatus {
				isStarted
				isFinished
				currentLotId
				currentLot
				currentPrice
				currentStatus
				currentStep
				leaderId
			}
			status {
				isStarted
				isFinished
				currentLotId
				currentLot
				currentPrice
				currentStatus
				currentStep
				leaderId
			}
			previewStatus {
				isStarted
				isFinished
				currentLotId
				currentLot
				currentPrice
				currentStatus
				currentStep
				leaderId
			}
			users
			soldLots {
				isFinished
				currentLotId
				currentPrice
				leaderId
			}
			allLots {
				items{
					id,
					auctionId,
					lot,
					name,
					description,
					nameEng,
					descriptionEng,
					gallery,
					status,
					started_price,
					ended_price,
					categoryId,
					categoryItem {
						id,
						value,
					},
				}
				totalCount
			}
			bets {
				createdAt
				bet
				userId
			}
			orderLots {
				id
				lot
				startedPrice
				finishedPrice
				userID
			}
			video {
				sessionId
			}
			usersConfig {
				userId
				ping
			}
			places {
				skinId
				bet
				userId
			}
		}
	}
`


export const ACTION_STATUS_SUBSCRIPTION = gql`
	subscription onChange($auctionId: Int!) {
		content: changeStatus(auctionId: $auctionId) {
			status {
				isStarted
				isFinished
				currentLotId
				currentLot
				currentPrice
				currentStatus
				currentStep
				leaderId
			}
			reOpenStatus {
				isStarted
				isFinished
				currentLotId
				currentLot
				currentPrice
				currentStatus
				currentStep
				leaderId
			}
			users
			video {
				sessionId
			}
			chat {
				text
				messageId
				userId
				timestamp
				avatar
				isAdministrator
			}
			soldLots {
				isFinished
				currentLotId
				currentPrice
				leaderId
			}
			usersConfig {
				userId
				ping
			}
			bets {
				createdAt
				bet
				userId
			}
			places {
				skinId
				bet
				userId
			}
			message
		}
	}
`

export const ACTIVE_MAKE_BET_QUERY = gql`
	mutation MakeBet($auctionId: Int!, $bet: Int!) {
		makeBet(auctionId: $auctionId, bet: $bet){
			status
		}
	}
`

export const ALL_AUCTIONS_ARCHIVE_CHATS_QUERY = gql`
	query GetAuction($from: Int!, $size: Int!) {
		auctionsArchive(pager: {from: $from, size: $size}) {
			auctions{
				id,
				name,
				startedAt,
			}
			totalCount
		}
	}
`

export const USER_CHATS_QUERY = gql`
	query GetItems($from: Int!, $size: Int!, $auctionId: Int!, $sortBy: MessagesSort) {
		messages: userMessages(pager: {from: $from, size: $size}, auctionId: $auctionId, sortBy: $sortBy) {
			totalCount,
			items {
				id,
				auctionId,
				userId,
				text,
				timestamp,
				isAdministrator,
				avatar,
			}
		}
	}
`

export const USER_ADMIN_CHATS_QUERY = gql`
	query GetItems($from: Int!, $size: Int!, $sortBy: MessagesSort) {
		messages: userAdminMessages(pager: {from: $from, size: $size}, sortBy: $sortBy) {
			items {
				id
				userId
				text
				createdAt
			}
			totalCount
		}
	}
`
