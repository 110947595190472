import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import defaultProps from 'recompose/defaultProps';
import setDisplayName from 'recompose/setDisplayName'; // @TODO: Подумать как отказаться от document.body в пользу HOC'a

var addTouchEnd = function addTouchEnd(callback) {
  return document.body.addEventListener('touchend', callback);
};

var removeTouchEnd = function removeTouchEnd(callback) {
  return document.body.removeEventListener('touchend', callback);
};

export default compose(setDisplayName('withTappable'), withState('isTapped', 'setTapped'), defaultProps({
  isTappable: true
}), withProps(function () {
  return {
    addTouchEndListener: addTouchEnd,
    removeTouchEndListener: removeTouchEnd
  };
}), withHandlers({
  onTouchStart: function onTouchStart(_ref) {
    var setTapped = _ref.setTapped,
        _onTouchStart = _ref.onTouchStart,
        addTouchEndListener = _ref.addTouchEndListener,
        removeTouchEndListener = _ref.removeTouchEndListener,
        isTappable = _ref.isTappable;
    return function (e) {
      var onTouchEnd = function onTouchEnd() {
        setTapped(false);
        removeTouchEndListener(onTouchEnd);
      };

      if (isTappable) {
        setTapped(true);
        addTouchEndListener(onTouchEnd);
      }

      _onTouchStart && _onTouchStart(e);
    };
  }
}));