export const ActionTypes = {
	ADD_MESSAGE: 'ADD_MESSAGE',
}

export const addMessage = (text,) => ({
	type: ActionTypes.ADD_MESSAGE,
	data: text,
})

const defaultEvents = {
	text: '',
}

export default function eventsReducer (state = defaultEvents, action,) {
	console.log(action.type,)
	switch (action.type) {
	case ActionTypes.ADD_MESSAGE:
		return {
			text: action.data,
		}
	default: return state
	}
}
