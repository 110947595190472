// eslint-disable-next-line no-unused-vars
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-redux-notify/dist/ReactReduxNotify.css'
import './index.css'
import App from './views/App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root',),)

if (process.env.NODE_ENV === 'production') {
	serviceWorker.register()
} else {
	serviceWorker.unregister()
}
