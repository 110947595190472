import _defineProperty from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
// $FlowFixMe
import { createPreset } from '@bbob/preset';
import defaultTags from './defaultTags';
export default (function (_ref) {
  var defaultTagsMap = _ref.defaultTagsMap,
      customBBCodesMap = _ref.customBBCodesMap;
  var defaultPreset = createPreset(defaultTags(defaultTagsMap));

  if (customBBCodesMap) {
    return defaultPreset.extend(function (tags) {
      return _objectSpread(_objectSpread({}, tags), Object.keys(customBBCodesMap).reduce(function (result, bbcode) {
        return _defineProperty({}, bbcode, function (node) {
          var urlAttribute = node.attrs.href ? {
            url: node.attrs.href
          } : {};
          return {
            tag: defaultTagsMap[customBBCodesMap[bbcode].as],
            attrs: _objectSpread(_objectSpread(_objectSpread({}, node.attrs), customBBCodesMap[bbcode].withProps(node.attrs)), urlAttribute),
            content: node.content
          };
        });
      }, {}));
    });
  }

  return defaultPreset;
});