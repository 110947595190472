// @flow
// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import { useQuery, } from '@apollo/react-hooks'
import AuctionHeaderComponent from 'auction.core/components/AuctionHeaderComponent'
import AuctionCatalogComponent from 'auction.core/components/AuctionCatalogComponent'
import { THEME_DARK, } from 'auction.core/providers/theme/constants'
import * as moment from 'moment'
import { injectIntl, } from 'react-intl'
import fileUrl from '../../../utilits/fileUrl'
import Background from './images/background.jpg'
import { ALL_AUCTIONS_ARCHIVE_QUERY, ALL_AUCTIONS_QUERY, } from '../../../api/auctions/requests'
// eslint-disable-next-line no-unused-vars

const AuctionsPageContainer = injectIntl(({
	data,
	isArchive,
	page,
	updatePage,
	pageSize,
	intl,
}) => (
	<>
		<AuctionHeaderComponent
			title={isArchive ? intl.messages['auctions.archive_title'] : intl.messages['auctions.auctions_title']}
			background={Background}
			isBig={false}
		/>
		<AuctionCatalogComponent
			items={data.auctions.map((item) => ({
				to: `/auction/${item.id}`,
				title: intl.locale === 'ru' || !item.nameEng ? item.name : item.nameEng,
				description: intl.locale === 'ru' || !item.descriptionEng ? item.description : item.descriptionEng,
				img: fileUrl(item.logo),
				pdfLink: item.pdfLink && item.pdfLink !== 'none' ? fileUrl(item.pdfLink) : '',
				date: moment(item.started_at).format('YYYY-MM-DD'),
				isPriceVisible: false,
			}))}
			totalCount={data.totalCount}
			isPriceVisible={false}
			pageSize={pageSize}
			page={page}
			theme={THEME_DARK}
			isArchive={isArchive}
			onChange={updatePage}
			catalogText='Каталог'
		/>
	</>
))
/* eslint-enable */

export default ({ match, intl, ...props }) => {
	const isArchive = match.path === '/archive'
	const query = isArchive ? ALL_AUCTIONS_ARCHIVE_QUERY : ALL_AUCTIONS_QUERY
	const field = isArchive ? 'auctionsArchive' : 'auctionsWithoutArchive'
	const [page, updatePage, ] = useState(1,)
	const [pageSize, updatePageSize, ] = useState(24,)
	const { loading, data, error, } = useQuery(query,
		{
			variables: {
				from: (page - 1) * pageSize,
				size: pageSize,
			},
		},)

	if (loading || !data) return <div className='loading' />
	if (error) return <p>Error :(</p>

	return (
		<AuctionsPageContainer
			{...props}
			isArchive={isArchive}
			data={data[field]}
			page={page}
			updatePage={updatePage}
			pageSize={pageSize}
			updatePageSize={updatePageSize}
		/>
	)
}
