export const ActionTypes = {
	SET_PROFILE: 'SET_PROFILE',
	UPDATE_PROFILE: 'UPDATE_PROFILE',
	REMOVE_PROFILE: 'REMOVE_PROFILE',
}

export const setProfile = (profile,) => {
	localStorage.setItem('__user_profile', JSON.stringify(profile))
	return ({
		type: ActionTypes.SET_PROFILE,
		data: profile,
	})
}

export const updateProfile = (profile,) => {
	localStorage.setItem('__user_profile', JSON.stringify(profile))
	return ({
		type: ActionTypes.UPDATE_PROFILE,
		data: profile,
	})
}

export const removeProfile = () => {
	localStorage.removeItem('__user_profile')
	return ({
		type: ActionTypes.REMOVE_PROFILE,
	})
}

const getDefaultProfile = () => {
	const profile = localStorage.getItem('__user_profile')
	if (profile) {
		return JSON.parse(profile)
	}
	return {}
}

const defaultProfile = getDefaultProfile()

export default function profileReducer (state = defaultProfile, action,) {
	switch (action.type) {
	case ActionTypes.SET_PROFILE:
		return {
			...action.data,
		}
	case ActionTypes.UPDATE_PROFILE:
		return {
			...action.data,
		}
	case ActionTypes.REMOVE_PROFILE:
		return {}
	default: return state
	}
}
