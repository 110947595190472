// @flow
// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import { useQuery, } from '@apollo/react-hooks'
import AuctionSearchComponent from 'auction.core/components/AuctionSearchComponent'
import AuctionCatalogComponent from 'auction.core/components/AuctionCatalogComponent'
import * as queryString from 'query-string'
import { injectIntl, } from 'react-intl'
import fileUrl from '../../../utilits/fileUrl'
import { ITEM_QUERY_SEARCH, } from '../../../api/auctions/requests'
// eslint-disable-next-line no-unused-vars

const AuctionsPageContainer = injectIntl(({
	data,
	search,
	onUpdateSearch,
	page,
	isLoading,
	updatePage,
	pageSize,
	intl,
}) => (
	<>
		<AuctionSearchComponent
			title={intl.messages['menu.search_button']}
			search={search}
			result={`${intl.messages['result.title']}: ${data.totalCount}`}
			onChange={onUpdateSearch}
		/>
		<AuctionCatalogComponent
			items={(isLoading ? [] : data.items).map((item) => ({
				to: `/item/${item.id}`,
				price: item.started_price,
				title: intl.locale === 'ru' || !item.nameEng ? item.name : item.nameEng,
				description: intl.locale === 'ru' || !item.descriptionEng ? item.description : item.descriptionEng,
				img: fileUrl(item.gallery[0] || 'logoAuction.png'),
			}))}
			isPriceVisible
			totalCount={isLoading ? 0 : data.totalCount}
			pageSize={pageSize}
			page={page}
			onChange={updatePage}
		/>
	</>
))
/* eslint-enable */

export default ({ location, history, ...props }) => {
	const [page, updatePage, ] = useState(1,)
	const [pageSize, ] = useState(24,)
	const { loading, data, } = useQuery(ITEM_QUERY_SEARCH,
		{
			variables: {
				offset: (page - 1) * pageSize,
				limit: pageSize,
				search: queryString.parse(location.search).search || '',
			},
		},)

	const onUpdateSearch = (input) => {
		history.push({
			pathname: '/search',
			search: `?search=${input}`,
		})
	}

	return (
		<AuctionsPageContainer
			{...props}
			isLoading={(loading || !data)}
			data={(!data ? [] : data.search)}
			page={page}
			updatePage={updatePage}
			pageSize={pageSize}
			search={queryString.parse(location.search).search}
			onUpdateSearch={onUpdateSearch}
		/>
	)
}
