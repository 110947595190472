import styles from './notification.module.css'

export default {
	actionBtn: '',
	close: styles.close,
	'close-all': '',
	content: '',
	'has-close': styles.hasClose,
	'has-close-all': '',
	'has-close-all--noDismiss': '',
	icon: '',
	item: '',
	item__btnBar: '',
	item__message: '',
	'no-close': '',
	'notification--error': styles.error,
	'notification--info': styles.info,
	'notification--success': styles.success,
	'notification--warning': styles.warning,
}
