export const FONT_TOGGLE = 'fonts/FONT_TOGGLE'

export function toggleFonts () {
	return {
		type: FONT_TOGGLE,
	}
}

export default function fonts (state = { isFontsLoaded: false, }, action,) {
	switch (action.type) {
	case FONT_TOGGLE:
		return {
			...state,
			isFontsLoaded: !state.isFontsLoaded,
		}
	default:
		return state
	}
}
