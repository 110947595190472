export const ContactsTypes = {
	UPDATE_CONTACTS: 'UPDATE_CONTACTS',
}

export const addContacts = (contacts,) => ({
	type: ContactsTypes.UPDATE_CONTACTS,
	data: contacts.reduce((acc, item) => ({ ...acc, [item.name]: item.value, [`${item.name}Eng`]: item.valueEng, }), {}),
})

const defaultContacts = {}

export default function contactsReducer (state = defaultContacts, action,) {
	switch (action.type) {
	case ContactsTypes.UPDATE_CONTACTS:
		return {
			...state,
			...action.data,
		}
	default: return state
	}
}
