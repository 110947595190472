const defaultMenu = {
	list: [
		{
			to: '/user/profile',
			text: 'menu.profile',
			id: 'profile',
		},
		{
			to: '/user/messages',
			text: 'menu.messages',
			id: 'messages',
		},
		{
			to: '/user/verify',
			text: 'menu.verify',
			id: 'verify',
		},
		{
			to: '/user/lots',
			text: 'menu.lots',
			id: 'lots',
		},
		{
			to: '/user/favorites',
			text: 'menu.favorites',
			id: 'favorites',
		},
		{
			to: '/user/exit',
			text: 'menu.exit',
			id: 'exit',
		},
	],
}

export default function menuReducer (state = defaultMenu, action,) {
	return state
}
