// @flow
import { removeToken, setToken, } from '../store/reducers/auth'
import storageHelper from '../helpers/storageHelper'

export const onSetToken = (token: string,) => (dispatch,) => {
	storageHelper.set('__token__', token, 'cookie')
	dispatch(setToken(token,),)
}

export const onRemoveToken = () => (dispatch,) => {
	storageHelper.remove('__token__', 'cookie')
	dispatch(removeToken(),)
}
