// @flow
// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import compose from 'recompose/compose'
import { useQuery, } from '@apollo/react-hooks'
import AuctionHeaderComponent from 'auction.core/components/AuctionHeaderComponent'
import AuctionSidebarComponent from 'auction.core/components/AuctionSidebarComponent'
import AuctionCatalogComponent from 'auction.core/components/AuctionCatalogComponent'
import HistoryElement from 'auction.core/components/HistoryElement'
import { connect, } from 'react-redux'
import { injectIntl, } from 'react-intl'
import { AUCTIONS_QUERY, } from '../../../api/auctions/requests'
import fileUrl from '../../../utilits/fileUrl'
import AucPageOne from '../../../assets/auc1.png'
import AucPageTwo from '../../../assets/auc2.png'
// eslint-disable-next-line no-unused-vars

const getSumm = (
	{ auctionId, userId, itemId, rate, isOutBid, },
	currentSum,
	currentUserId,
	intl,
) => {
	if (!rate) {
		return {
			priceText: intl.messages['bids.start'],
			color: 'white',
			price: currentSum,
		}
	}
	if (`${currentUserId}` === `${userId}`) {
		return {
			priceText: intl.messages['bids.up'],
			color: 'green',
			price: rate,
		}
	}
	if (isOutBid) {
		return {
			priceText: intl.messages['bids.down'],
			color: 'red',
			price: rate,
		}
	}
	return {
		priceText: intl.messages['bids.leader'],
		color: 'blue',
		price: rate,
	}
}

/*eslint-disable */
const ItemsPageContainer = injectIntl(compose(
	connect(
		state => ({
			profile: state.profile || {},
		}),
		null
	),
)(({
		 auction,
		 currentOptionValue,
		 currentCategoryValue,
		 optionsOrderOptions,
		 optionsCategoryOptions,
		 updateOptionValue,
		 updateCategoryValue,
		 page,
		 profile,
		 updatePage,
		 pageSize,
		 intl,
		 isLoading,
	 }) => {
	return (
		<>
			<AuctionHeaderComponent
				title={intl.locale === 'ru' || !(auction || {}).nameEng ? (auction || {}).name : (auction || {}).nameEng}
				background={fileUrl((auction || {}).background)}
			/>
			<AuctionSidebarComponent
				title={intl.messages['lots.catalog']}
				orderValue={currentOptionValue.value}
				orderOptions={optionsOrderOptions}
				onChangeOrder={updateOptionValue}
				categoryValue={currentCategoryValue.value}
				categoryOptions={optionsCategoryOptions}
				onChangeCategories={updateCategoryValue}
			/>
			<AuctionCatalogComponent
				items={(isLoading ? [] : auction.items.items).map((item) => {
					const images = (item.gallery || [])
					return {
						to: `/item/${item.id}`,
						title: intl.locale === 'ru' || !item.nameEng ? item.name : item.nameEng,
						description: intl.locale === 'ru' || !item.descriptionEng ? item.description : item.descriptionEng,
						img: fileUrl(images[0] || 'logoAuction.png'),
						...getSumm((item.maxBit || {}), item.started_price, profile.id, intl,)
					}
				})}
				totalCount={isLoading ? 0 : auction.items.totalCount}
				isPriceVisible={true}
				pageSize={pageSize}
				page={page}
				onChange={updatePage}
			/>
			<HistoryElement
				isInner
				{...{
					image: AucPageOne,
					icon: AucPageTwo,
					title: intl.messages['contacts.mark'],
					description: intl.messages['contacts.mark_description'],
					side: 'center',
				}}
			/>
		</>
	)
}))
/* eslint-enable */

export default injectIntl(({ match, intl, ...props }) => {
	const [optionsOrderOptions, ] = useState([
		{
			value: 'LOT',
			label: intl.messages['sort.lot'],
		},
		{
			value: 'STARTED_PRICE',
			label: intl.messages['sort.price'],
		},
		{
			value: 'NAME',
			label: intl.messages['sort.name'],
		},
	],)
	const [optionsCategoryOptions, ] = useState([
		{
			value: 0,
			label: intl.messages['sort.all'],
		},
	],)
	const [currentOptionValue, updateOptionValue, ] = useState({
		value: 'LOT',
		label: intl.messages['sort.lot'],
	},)
	const [currentCategoryValue, updateCategoryValue, ] = useState(
		{
			value: '0',
			label: intl.messages['sort.all'],
		},
	)
	const [page, updatePage, ] = useState(1,)
	const [pageSize, updatePageSize, ] = useState(24,)
	const { loading, data, } = useQuery(AUCTIONS_QUERY,
		{
			variables: {
				from: (page - 1) * pageSize,
				size: pageSize,
				order_by: currentOptionValue.value,
				auctionId: match.params.id,
				categoryId: currentCategoryValue.value === '0' ? null : currentCategoryValue.value,
			},
		},)

	const categories = [
		...optionsCategoryOptions,
		...(!data || !data.auction ? [] : data.auction.categoryItems).map(({ id, value, }) => ({
			value: id,
			label: value,
		})),
	]

	const updateCurrentOption = (name,) => {
		/* eslint-disable */
		const value = optionsOrderOptions.find((x) => x.value === name,)
		/* eslint-disable */
		updateOptionValue(value,)
	}
	const updateCurrentCategoryOption = (name,) => {
		/* eslint-disable */
		const value = categories.find((x) => x.value === name,)
		/* eslint-disable */
		updateCategoryValue(value,)
	}
	return (
		<ItemsPageContainer
			{...props}
			isLoading={loading || !data}
			auction={(data || {}).auction}
			currentCategoryValue={currentCategoryValue}
			currentOptionValue={currentOptionValue}
			updateOptionValue={updateCurrentOption}
			updateCategoryValue={updateCurrentCategoryOption}
			optionsCategoryOptions={categories}
			page={page}
			updatePage={updatePage}
			pageSize={pageSize}
			optionsOrderOptions={optionsOrderOptions}
			updatePageSize={updatePageSize}
		/>
	)
})
