// eslint-disable-next-line no-unused-vars
import React from 'react'
import MainWrapperComponent
	from 'auction.core/assets/components/main/mainWrapper/MainWrapperComponent'
import ModalComponent from 'auction.core/assets/components/modal/ModalComponent'
import { Notify, } from 'react-redux-notify'
import fileUrl from '../../../utilits/fileUrl'
import LoginContainer from '../../../containers/Login/LoginContainer'
import RegisterContainer from '../../../containers/Register/RegisterContainer'
import ForgotContainer from '../../../containers/Forgot/ForgotContainer'

export default ({
	profile,
	children,
	onClickUser,
	onOpenLogin,
	onClickLanguageElement,
	onOpenRegister,
	onOpenForgot,
	footerContent,
	headerContent,
	onCloseModal,
	visibleModal,
	loginTitle,
	registerTitle,
	forgotTitle,
},) => (
	<MainWrapperComponent
		{...headerContent}
		{...footerContent}
		onClickLogin={onOpenLogin}
		onClickRegister={onOpenRegister}
		onClickLanguageElement={onClickLanguageElement}
		username={profile.first_name}
		userAvatarUrl={fileUrl(profile.avatar,)}
		onClickUser={onClickUser}
		isLanguageInit
		onEmailChange={() => {}}
	>
		{children}
		<ModalComponent onClose={onCloseModal} isOpen={visibleModal === 'login'} title={loginTitle}>
			<LoginContainer
				onCloseModalLogin={onCloseModal}
				className='wrapperForm'
				onOpenRegister={onOpenRegister}
				onOpenForgot={onOpenForgot}
			/>
		</ModalComponent>
		<ModalComponent onClose={onCloseModal} isOpen={visibleModal === 'register'} title={registerTitle}>
			<RegisterContainer onCloseModalRegister={onCloseModal} className='wrapperForm' />
		</ModalComponent>
		<ModalComponent onClose={onCloseModal} isOpen={visibleModal === 'forgot'} title={forgotTitle}>
			<ForgotContainer onCloseModal={onCloseModal} className='wrapperForm' />
		</ModalComponent>
		<Notify />
	</MainWrapperComponent>
)
