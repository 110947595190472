// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import compose from 'recompose/compose'
import { connect, } from 'react-redux'
import withHandlers from 'recompose/withHandlers'
import { onRemoveToken, } from '../../../actions/authActions'
import { onRemoveProfile, } from '../../../actions/profileActions'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
const UserProfilePageContainer = compose(
	connect(
		null,
		{
			logOut: onRemoveToken,
			clear: onRemoveProfile,
		}
	),
	withHandlers(() => ({
		onLogOut: ({ logOut, clear, history }) => () => {
			logOut()
			clear()
			history.push('/')
		},
	})),
)(({ onLogOut }) => {
	onLogOut()
	return null
})
/* eslint-enable */

export default UserProfilePageContainer
