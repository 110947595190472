// eslint-disable-next-line no-unused-vars
import React from 'react'
// eslint-disable-next-line standard/object-curly-even-spacing
import { Provider, } from 'react-redux'
import { ApolloProvider, } from '@apollo/react-hooks'
import Routes from './routes'
import store from '../store/index'
import clientGQL from '../api/graphql'

export default function App () {
	return (
		<div id='app-container'>
			<Provider store={store}>
				<ApolloProvider client={clientGQL}>
					<Routes store={store} />
				</ApolloProvider>
			</Provider>
		</div>
	)
}
