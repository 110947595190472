export default {
	'menu.enter': 'Login',
	'menu.create_account': 'Sign up',
	'menu.login': 'Sign in',
	'menu.search': 'Find',
	'menu.search_button': 'Find',
	'menu.auctions': 'Auctions',
	'menu.about_us': 'About',
	'menu.rules': 'Rules',
	'menu.contacts': 'Contacts',
	'menu.profile': 'Profile',
	'menu.verify': 'Verificatoin',
	'menu.lots': 'Your bids',
	'menu.favorites': 'Favorites',
	'menu.messages': 'Chats',
	'menu.exit': 'Exit',

	'profile.profile_change': 'Profile updated',
	'profile.avatar_change': 'Avatar updates!',
	'profile.save_button': 'Save',
	'profile.firstName': 'First name',
	'profile.lastName': 'Second name',
	'profile.phone': 'Phone',
	'profile.company': 'Company',
	'profile.address': 'Adress',
	'profile.choose_character': 'Choose your avator',
	'profile.choose': 'Choose',
	'profile.rating_text': 'rating',
	'profile.status_text': 'purchase',
	'profile.role_text': 'sales',

	'verify.message_ok': 'Verification request accepted!',
	'verify.message_send': 'Pass verification and participate in the auction',
	'verify.message_email': 'The email will be sent to verify your email address. For sending a letter, click on the button.',
	'verify.email': 'Email',
	'verify.password': 'Password',
	'verify.firstName': 'Fist name',
	'verify.lastName': 'Second name',
	'verify.phone': 'Phone',
	'verify.company': 'Company',
	'verify.index': 'index',
	'verify.country': 'Country',
	'verify.city': 'City/Town',
	'verify.address': 'Adress',
	'verify.shtat': 'State (region)',
	'verify.random_text': 'text up to 300  characters',
	'verify.passport_up': 'Main page of ID/Passport',
	'verify.passport_down': 'Secon page of ID/Passport ',
	'verify.address_delivery': 'Adress',
	'verify.message': 'Message',
	'verify.form': 'Document upload form',
	'verify.send': 'Send for verification',
	'verify.thanks': 'Thank you, your application has been accepted! We will contact you shortly.',
	'verify.register': 'Sign Up',
	'verify.forgot': 'Forgot',
	'verify.back': 'Back',
	'verify.agree': 'Agree',
	'verify.rules': 'Auctions Rules&Agreement',
	'verify.change': 'Change',
	'verify.repeat': 'Repeat password',
	'verify.sendCode': 'Send',

	'user_chats.not_found': 'You have no messages at the moment.',
	'user_lots.not_found': 'You do not have lots at the moment',
	'user_lots.sort': 'Sorting',
	'user_lots.search': 'Find',

	'menu.auth': 'Login',
	'menu.register': 'Sign up',
	'menu.forgot': 'Password recovery',

	'not_found.page': 'Page not found',
	'not_found.description': 'Server Not Found error message is a Hypertext Transfer Protocol standard response code, in computer network communications',
	'not_found.link': 'to Home Page',

	'main.auction': 'Pugachevs Auction house',
	'main.future': 'Upcoming auctions',
	'main.past': 'Past auctions',
	'main.past_auction': 'Past Auction',
	'main.popular': 'Popular lots',
	'main.online': 'Enter the auction',
	'main.results': 'All auction results',

	'contacts.mark': 'Stamps',
	'contacts.mark_description': 'Philatelists are stamp collectors. Stamp collectors are the world\'s most zealous buyers. Realizing this, our auction offers to buy collectible stamps that you like. People who are keen on this business are actively collecting and looking for stamps. Of particular interest are rare collectible stamps, which every self-respecting collector wants to buy. \n Some of the papers with ribbed edges have been issued in a limited edition. Such specimens deserve special attention and are expensive. We bring to your attention worthy collections. ',

	'rules.title': 'Rules for participation in the auction',
	'rules.desc': 'General Provisions',

	'about.title': 'About us',
	'result.title': 'Total Results',

	'lots.catalog': 'Lot catalog',

	'bids.start': 'Starting price',
	'bids.up': 'Your highest bid',
	'bids.down': 'Bet outbid',
	'bids.leader': 'Leading bid',
	'sort.lot': 'By lot',
	'sort.price': 'By price',
	'sort.name': 'By name',
	'sort.all': 'All',

	'lot.create_bid': 'Place a bet',
	'lot.start': 'Starting price: ',
	'lot.not_sold': 'Not sold',
	'lot.sold': 'Sold for',
	'lot.max_bid': 'Your maximum bet:',
	'lot.max_price': 'Price including commission',

	'auctions.archive_title': 'Archive',
	'auctions.auctions_title': 'Auctions',

	'online.create_bid': 'Place a bet',
	'online.last_chance': 'Last chance!',
	'online.new_price': 'Suggested price',
	'online.its_yours': 'This is your bet',
	'online.stop': 'Auction finished',
	'online.confirm': 'Confirmation of bet',
	'online.wait': 'Wait',
	'online.catalog': 'Catalog',
	'online.admin': 'Administrator',
	'online.exit': 'Exit',
	'online.enter_text': 'Message for send ...',
	'online.you': 'You',

	'online.leader': 'Leading bet',
	'online.congratulation': 'Congratulations!',
	'online.stop_message': 'Auction finished. Good purchases, you can see them in your personal account.',
	'online.my_sale': 'My purchases',

	'chat.admin': 'Administration messages',
	'chat.new': 'Newest',
	'chat.old': 'Oldest',

	'footer.subscribe': 'Subscribe to news',
	'footer.email': 'Your email',
	'footer.description': '"Auction House of the Pugachevs" - the name of the action held in accordance with the User Agreement posted on this site.',
}
