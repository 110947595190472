// eslint-disable-next-line no-unused-vars
import React from 'react'
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom'
import * as history from 'history'
import { IntlProvider, } from 'react-intl'
import { connect, } from 'react-redux'
import AppLocale from '../lang/index'
import PageWrapper from './components/pageWrapper/PageWrapperContainer'
import Main from './pages/mainPage/MainPageContainer'
import UserProfile from './pages/userPages/UserProfilePageContainer'
import UserVerify from './pages/userPages/UserVerifyPageContainer'
import UserChat from './pages/userPages/UserChatArchivePageContainer'
import UserLots from './pages/userPages/UserLotsPageContainer'
import UserFavorite from './pages/userPages/UserFavoritePageContainer'
import UserExit from './pages/userPages/UserExitPageContainer'
import ItemsPage from './pages/auctions/ItemsPageContainer'
import ItemPage from './pages/auctions/ItemPageContainer'
import AuctionsPage from './pages/auctions/AuctionsPageContainer'
import SearchPage from './pages/auctions/SearchPageContainer'
import ContactsPage from './pages/mainPage/ContactsPageContainer'
import AboutUsPage from './pages/mainPage/AboutUsPageContainer'
import NotFound from './pages/mainPage/NotFoundContainer'
import RulesPage from './pages/mainPage/RulesPageContainer'
import OnlinePage from './pages/auctions/OnlinePageContainer'

const App = ({ locale, }) => (
	<Router history={history}>
		<IntlProvider
			locale={AppLocale[locale].locale}
			messages={AppLocale[locale].messages}
		>
			<PageWrapper>
				<Switch>
					<Route exact path='/' component={Main} />
					<Route path='/user/profile' component={UserProfile} />
					<Route path='/user/verify' component={UserVerify} />
					<Route path='/user/exit' component={UserExit} />
					<Route path='/user/lots' component={UserLots} />
					<Route path='/user/favorites' component={UserFavorite} />
					<Route path='/user/messages' component={UserChat} />
					<Route path='/online/:id' component={OnlinePage} />
					<Route path='/auction/:id' component={ItemsPage} />
					<Route path='/auctions' component={AuctionsPage} />
					<Route path='/archive' component={AuctionsPage} />
					<Route path='/item/:id' component={ItemPage} />
					<Route path='/search' component={SearchPage} />
					<Route path='/contacts' component={ContactsPage} />
					<Route path='/about_us' component={AboutUsPage} />
					<Route path='/rules' component={RulesPage} />
					<Route component={NotFound} />
				</Switch>
			</PageWrapper>
		</IntlProvider>
	</Router>
)

const mapStateToProps = ({ settings, }) => {
	const { locale, } = settings
	return {
		locale,
	}
}
const mapActionsToProps = {}

export default connect(
	mapStateToProps,
	mapActionsToProps
)(App)
