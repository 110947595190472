export default {
	getUrlParameter: (name,) => {
		// eslint-disable-next-line no-useless-escape
		const parameter = name.replace(/[\[]/, '\\[',)
			.replace(/[\]]/, '\\]',)
		const regex = new RegExp(`[\\?&]${parameter}=([^&#]*)`,)
		const results = regex.exec(window.location.search,)
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' ',),)
	},
	headerVisible: (pathname = window.location.pathname,) => pathname !== '/',
}
