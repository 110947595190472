// @flow
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
import compose from 'recompose/compose'
import { useQuery, } from '@apollo/react-hooks'
import { connect, } from 'react-redux'
import UserChatArchiveComponent
	from 'auction.core/assets/components/user/userChatArchive/UserChatArchiveComponent'
import UserWrapperComponent
	from 'auction.core/assets/components/user/userWrapper/UserWrapperComponent'
import UserMenuComponent from 'auction.core/assets/components/user/userMenu/UserMenuComponent'
import LoadingElement from 'auction.core/components/LoadingElement'
import { injectIntl, } from 'react-intl'
import {
	ALL_AUCTIONS_ARCHIVE_CHATS_QUERY, USER_ADMIN_CHATS_QUERY, USER_CHATS_QUERY,
} from '../../../api/auctions/requests'
import fileUrl from '../../../utilits/fileUrl'
import { getMenuSelector, } from '../../../selectors/menuSelectors'

const UserChatArchive = ({
	auctionOptions,
	auctionValue,
	sortValue,
	onChangeAuction,
	onChangeSort,
	sortOptions,
	items,
	intl,
	...props
}) => {
	const [page, updatePage, ] = useState(1,)
	const [pageSize, ] = useState(1000,)
	const { isLoading, data, } = useQuery(auctionValue === '0' ? USER_ADMIN_CHATS_QUERY : USER_CHATS_QUERY,
		{
			variables: {
				from: (page - 1) * pageSize,
				size: pageSize,
				auctionId: auctionValue,
				sortBy: sortValue,
			},
		},)

	if (isLoading || !data || !auctionValue) return <LoadingElement />
	return (
		<UserChatArchiveComponent
			{...props}
			messages={(data.messages.items || []).map(({
				 id,
				 auctionId,
				 userId,
				 text,
				 timestamp,
				 isAdministrator,
				 avatar,
			 }) => ({
				id,
				auctionId,
				userId,
				text,
				timestamp,
				isAdminMessage: auctionValue === '0',
				isAdministrator,
				avatar: fileUrl(avatar || 'avatar.png'),
			}))}
			title={intl.messages['user_lots.sort']}
			auctionOptions={auctionOptions}
			auctionValue={auctionValue}
			sortValue={sortValue}
			onChangeAuction={onChangeAuction}
			onChangeSort={onChangeSort}
			sortOptions={sortOptions}
			totalCount={data.messages.totalCount}
			pageSize={pageSize}
			page={page}
			onChangePagination={updatePage}
		/>
	)
}

export default compose(
	connect(
		state => ({
			items: getMenuSelector(state),
			profile: state.profile,
			token: state.auth.token,
		}),
	),
)(injectIntl(({ location, history, profile, items, emptyText, intl, ...props }) => {
	const isProfileEmpty = Object.entries(profile).length === 0 && profile.constructor === Object
	const [auctionValue, changeAuctionValue, ] = useState('0',)
	const [sortValue, changeSorts, ] = useState('NEW',)
	const [auctionOptions, changeAuctions, ] = useState([{
		value: 0,
		label: intl.messages['chat.admin'],
	}, ],)

	const [sortOptions, ] = useState([
		{
			value: 'OLD',
			label: intl.messages['chat.new'],
		},
		{
			value: 'NEW',
			label: intl.messages['chat.old'],
		}, ],)
	// eslint-disable-next-line max-len

	const { loading, data, } = useQuery(ALL_AUCTIONS_ARCHIVE_CHATS_QUERY,
		{
			variables: {
				from: 0,
				size: 1000,
			},
		},)

	useEffect(() => {
		let auctions = [{
			value: '0',
			label: 'Сообщения администрации',
		}, ]
		const value = '0'
		if (data && data.auctionsArchive.auctions) {
			auctions = [
				...auctions,
				...data.auctionsArchive.auctions.reduce((acc, { id, name, }) => ([...acc, {
					value: id,
					label: name,
				}, ]), []),
			]
		}
		changeAuctions(auctions)
		changeAuctionValue(value)
	}, [data, intl.locale, ])

	const onChangeAuction = (value) => {
		changeAuctionValue(value)
	}

	const onChangeSort = (value) => {
		changeSorts(value)
	}

	return (
		<div style={{ backgroundColor: 'rgba(225, 225, 225, 0.37)', minHeight: '100%', }}>
			<UserWrapperComponent>
				<UserMenuComponent
					items={items.map((item) => ({ ...item, text: intl.messages[item.text], }))}
				/>
				{(isProfileEmpty || loading) && <LoadingElement />}
				{!(isProfileEmpty || loading || !data) && (
					<UserChatArchive
						auctionOptions={auctionOptions}
						auctionValue={auctionValue}
						sortValue={sortValue}
						onChangeAuction={onChangeAuction}
						onChangeSort={onChangeSort}
						sortOptions={sortOptions}
						intl={intl}
						items={items}
					/>
				)}
			</UserWrapperComponent>
		</div>
	)
}))
