// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import UserMenuComponent from 'auction.core/components/UserMenuComponent'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import { connect, } from 'react-redux'
import UserWrapperComponent from 'auction.core/components/UserWrapperComponent'
import UserProfileComponent from 'auction.core/components/UserProfileComponent'
import LoadingElement from 'auction.core/components/LoadingElement'
import withProps from 'recompose/withProps'
import { withHandlers, withState, } from 'recompose'
import BigLoadingElement from 'auction.core/assets/elements/bigLoading/BigLoadingElement'
import { injectIntl, } from 'react-intl'
import fileUrl from '../../../utilits/fileUrl'
import { updateUserAvatar, updateUserProfile, updateUserSkinId, } from '../../../actions/profileActions'
import authService from '../../../services/authService'
import { getStatusContent, } from '../../../utilits/contents'
import { getMenuSelector, } from '../../../selectors/menuSelectors'
import { sendNotifySuccess, } from '../../../actions/notifyActions'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
const UserProfilePageContainer = compose(
	connect(
		state => ({
			items: getMenuSelector(state),
			profile: state.profile,
			token: state.auth.token,
		}),
		{
			setUserAvatar: updateUserAvatar,
			setUserProfile: updateUserProfile,
			onSendNotifySuccess: sendNotifySuccess,
			onUpdateUserSkinId: updateUserSkinId,
		}
	),

	lifecycle({
		componentDidMount() {
			if (!this.props.token) {
				this.props.history.push('/')
			}
		},
	}),
	withState('isLoading', 'updateLoading', false,),
	withHandlers({
		onValidate: () => (fields) => authService.validateProfile(fields),
		onSubmit: ({ intl, setUserProfile, updateLoading, onSendNotifySuccess, }) => async (fields) => {
			updateLoading(true)
			await setUserProfile(fields)
			onSendNotifySuccess(intl.messages['profile.profile_change'])
			updateLoading(false)
		},
		onUpdateAvatar: ({ intl, setUserAvatar, updateLoading, onSendNotifySuccess }) => async (avatar) => {
			updateLoading(true)
			await setUserAvatar(avatar)
			onSendNotifySuccess(intl.messages['profile.avatar_change'])
			updateLoading(false)
		},
		onUpdateSkin: ({intl, onUpdateUserSkinId, updateLoading, onSendNotifySuccess, }) => async (skinId) => {
			updateLoading(true)
			await onUpdateUserSkinId(skinId)
			onSendNotifySuccess(intl.messages['profile.profile_change'])
			updateLoading(false)
		},
	}),
	withProps(({ profile, intl }) => ({
		isProfileEmpty: Object.entries(profile).length === 0 && profile.constructor === Object,
		name: profile.first_name,
		purchase: 0,
		sale: 0,
		rating: 0,
		buttonText: intl.messages['profile.save_button'],
		form: {
			first_name: {
				id: 1,
				name: 'first_name',
				label: intl.messages['profile.firstName'],
				type: 'text',
				value: profile.first_name,
			},
			last_name: {
				id: 1,
				name: 'last_name',
				label: intl.messages['profile.lastName'],
				type: 'text',
				value: profile.last_name,
			},
			phone: {
				id: 1,
				name: 'phone',
				label: intl.messages['profile.phone'],
				type: 'phone',
				value: profile.phone,
			},
			company: {
				id: 1,
				name: 'company',
				label: intl.messages['profile.company'],
				type: 'company',
				value: profile.company,
			},
			address: {
				id: 1,
				name: 'address',
				label: intl.messages['profile.address'],
				type: 'address',
				value: profile.address,
			},
		},
		formList: [
			'first_name',
			'last_name',
			'phone',
			'company',
			'address',
		],
		ratingText: intl.messages['profile.rating_text'],
		statusText: intl.messages['profile.status_text'],
		roleText: intl.messages['profile.role_text'],
		submitText: intl.messages['profile.save_button'],
		skinTitle: intl.messages['profile.choose_character'],
		skinButtonText: intl.messages['profile.choose'],
		skinButtonTitle: intl.messages['profile.choose_character'],
	}))
)(({
			items,
			name,
			purchase,
			sale,
			rating,
			ratingText,
			statusText,
			roleText,
			buttonText,
			form,
			formList,
			submitText,
			profile,
			skinTitle,
			skinButtonText,
			skinButtonTitle,
			isProfileEmpty,
			isLoading,
			skinId,
			onUpdateAvatar,
			onValidate,
			onUpdateSkin,
			onSubmit,
			intl,
	 }) => {
	return (
		<div style={{ backgroundColor: 'rgba(225, 225, 225, 0.37)', minHeight: '100%', }}>
			<UserWrapperComponent>
				<UserMenuComponent
					items={items.map((item) => ({ ...item, text: intl.messages[item.text], }))}
				/>
				{isLoading && <BigLoadingElement />}
				{isProfileEmpty && <LoadingElement />}
				{!isProfileEmpty && (
					<UserProfileComponent
						onUpdateAvatar={onUpdateAvatar}
						onValidate={onValidate}
						onSubmit={onSubmit}
						name={name}
						purchase={purchase}
						sale={sale}
						rating={rating}
						ratingText={ratingText}
						statusText={statusText}
						roleText={roleText}
						buttonText={buttonText}
						form={form}
						formList={formList}
						submitText={submitText}
						status={profile.status}
						role={profile.role}
						statusContent={getStatusContent(profile.status, profile.role)}
						onUpdateSkin={onUpdateSkin}
						skinId={profile.skin_id}
						skinTitle={skinTitle}
						skinButtonText={skinButtonText}
						skinButtonTitle={skinButtonTitle}
						avatar={fileUrl(profile.avatar)}
					/>
				)}
			</UserWrapperComponent>
		</div>
	)
})
/* eslint-enable */

export default injectIntl(UserProfilePageContainer)
