import storageHelper from '../../helpers/storageHelper'

export const ActionTypes = {
	SET_TOKEN: 'SET_TOKEN',
	REMOVE_TOKEN: 'REMOVE_TOKEN',
}

export const setToken = (token,) => ({
	type: ActionTypes.SET_TOKEN,
	token,
})

export const removeToken = () => ({
	type: ActionTypes.REMOVE_TOKEN,
})

const defaultUser = {
	token: storageHelper.get('__token__', 'cookie') || '',
}

export default function userReducer (state = defaultUser, action,) {
	switch (action.type) {
	case ActionTypes.SET_TOKEN:
		return {
			...state,
			token: action.token,
		}
	case ActionTypes.REMOVE_TOKEN:
		return {
			...state,
			token: '',
		}
	default: return state
	}
}
