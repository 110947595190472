import withProps from 'recompose/withProps';
import cn from 'classnames';
import createTypeElement from './type/createTypeElement';
export default (function () {
  var className = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var as = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'div';
  return withProps(function (props) {
    return {
      className: cn(typeof className === 'function' ? className(props) : className, props.className),
      as: props.as || as
    };
  })(createTypeElement);
});