import _defineProperty from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";

var sanitize = function sanitize(attrs, url) {
  return Object.keys(attrs).reduce(function (result, key) {
    if (key === url) {
      return _objectSpread(_objectSpread({}, result), {}, {
        href: url
      });
    }

    return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, key, attrs[key]));
  }, {});
};

var getUniqAttr = function getUniqAttr(attrs) {
  return Object.keys(attrs).reduce(function (res, key) {
    return attrs[key] === key ? attrs[key] : null;
  }, null);
};

var getUrl = function getUrl(node, context) {
  return getUniqAttr(node.attrs) ? getUniqAttr(node.attrs) : context.render(node.content);
};

export default (function (defaultTagsMap) {
  return _objectSpread(_objectSpread({}, Object.keys(defaultTagsMap).reduce(function (result, defaultTag) {
    return _defineProperty({}, defaultTag, function (node) {
      return {
        tag: defaultTagsMap[defaultTag],
        attrs: node.attrs,
        content: node.content
      };
    });
  }, {})), {}, {
    b: function b(node) {
      return {
        tag: defaultTagsMap.b,
        attrs: node.attrs,
        content: node.content
      };
    },
    i: function i(node) {
      return {
        tag: defaultTagsMap.i,
        attrs: node.attrs,
        content: node.content
      };
    },
    s: function s(node) {
      return {
        tag: defaultTagsMap.s,
        attrs: node.attrs,
        content: node.content
      };
    },
    normal: function normal(node) {
      return {
        tag: defaultTagsMap.normal,
        attrs: node.attrs,
        content: node.content
      };
    },
    nowrap: function nowrap(node) {
      return {
        tag: defaultTagsMap.nowrap,
        attrs: node.attrs,
        content: node.content
      };
    },
    sup: function sup(node) {
      return {
        tag: defaultTagsMap.sup,
        attrs: node.attrs,
        content: node.content
      };
    },
    sub: function sub(node) {
      return {
        tag: defaultTagsMap.sub,
        attrs: node.attrs,
        content: node.content
      };
    },
    kbd: function kbd(node) {
      return {
        tag: defaultTagsMap.kbd,
        attrs: node.attrs,
        content: node.content
      };
    },
    u: function u(node) {
      return {
        tag: defaultTagsMap.u,
        attrs: node.attrs,
        content: node.content
      };
    },
    br: function br() {
      return {
        tag: defaultTagsMap.br,
        attrs: null,
        content: null
      };
    },
    quote: function quote(_ref2) {
      var content = _ref2.content,
          u = _ref2.attrs.u;
      return {
        tag: defaultTagsMap.quote,
        attrs: {
          author: u ? u.substr(1, u.length - 2) : 'username'
        },
        content: content
      };
    },
    accent: function accent(node) {
      return {
        tag: defaultTagsMap.accent,
        attrs: node.attrs,
        content: node.content
      };
    },
    avatar: function avatar(node) {
      return {
        tag: defaultTagsMap.avatar,
        attrs: _objectSpread(_objectSpread({}, node.attrs), {}, {
          url: node.attrs.href || Object.values(node.attrs)[0]
        }),
        content: node.content
      };
    },
    user: function user(node) {
      return {
        tag: defaultTagsMap.user,
        attrs: _objectSpread(_objectSpread({}, node.attrs), {}, {
          username: node.content.join(''),
          profileUrl: node.attrs.href || Object.values(node.attrs)[0]
        })
      };
    },
    icon: function icon(node) {
      return {
        tag: defaultTagsMap.icon,
        attrs: node.attrs,
        content: node.content
      };
    },
    url: function url(node, context) {
      var parentUrl = getUrl(node, context);
      return {
        tag: defaultTagsMap.url,
        attrs: _objectSpread(_objectSpread({}, sanitize(node.attrs, parentUrl)), {}, {
          url: node.attrs.href ? node.attrs.href : parentUrl
        }),
        content: node.content
      };
    },
    img: function img(node) {
      return {
        tag: defaultTagsMap.img,
        attrs: _objectSpread(_objectSpread({}, node.attrs), {}, {
          size: +node.attrs.size,
          aspectRatio: +node.attrs.aspectRatio,
          url: node.attrs.href || Object.values(node.attrs)[0]
        })
      };
    },
    badge: function badge(node) {
      return {
        tag: defaultTagsMap.badge,
        attrs: _objectSpread(_objectSpread({}, node.attrs), {}, {
          text: node.content.join('')
        })
      };
    },
    emoji: function emoji(node) {
      return {
        tag: defaultTagsMap.emoji,
        attrs: node.attrs,
        content: null
      };
    }
  });
});