// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import compose from 'recompose/compose'
import { withProps, } from 'recompose'
import HistoryElement from 'auction.core/components/HistoryElement'
import { connect, } from 'react-redux'
import MapComponent from 'auction.core/components/MapComponent'
import { injectIntl, } from 'react-intl'
import AucPageOne from '../../../assets/auc1.png'
import AucPageTwo from '../../../assets/auc2.png'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
export default injectIntl(compose(
	connect(
		(state,) => ({
			contacts: state.contacts,
		}),
	),
	withProps(({ contacts, intl, }) => ({
		title: intl.messages['menu.contacts'],
		apiKey: contacts.apiKey || 'AIzaSyAjzk7nDKJvC9SgJdJz8PI2XRPPU-fEiKM',
		lat: (intl.locale === 'ru' ? contacts.lat : contacts.latEng) || '55.773702',
		lng: (intl.locale === 'ru' ? contacts.lng : contacts.lngEng) || '37.599220',
		phone: (intl.locale === 'ru' ? contacts.phone : contacts.phoneEng) || '+7 (916) 300 45 48',
		doublePhone: (intl.locale === 'ru' ? contacts.doublePhone : contacts.doublePhoneEng) || '+7 (499) 251 51 45',
		email: (intl.locale === 'ru' ? contacts.email : contacts.emailEng) || 'pugachev-auction@mail.ru',
		city: (intl.locale === 'ru' ? contacts.city : contacts.cityEng) || '125047 г Москва, Метро Маяковская',
		address: (intl.locale === 'ru' ? contacts.address : contacts.addressEng) || '1-й Тверской Ямской переулок 18 офис 5 «Аукционный дом Пугачевых».',
	})),
)(({
		intl,
		 ...props
	 }) => {
	return (
		<>
			<MapComponent
				{...props}
			/>
			<HistoryElement
				isInner
				{...{
					image: AucPageOne,
					icon: AucPageTwo,
					title: intl.messages['contacts.mark'],
					description: intl.messages['contacts.mark_description'],
					side: 'center',
				}}
			/>
		</>
	)
}))
