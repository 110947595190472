import ruMessages from '../locales/ru_RU'

const RuLang = {
	messages: {
		...ruMessages,
	},
	locale: 'ru',
}

export default RuLang
