// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import compose from 'recompose/compose'
import { withProps, } from 'recompose'
import { connect, } from 'react-redux'
import withHandlers from 'recompose/withHandlers'
import NotFoundContentComponent from 'auction.core/components/NotFoundContentComponent'
import { injectIntl, } from 'react-intl'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
export default injectIntl(compose(
	connect(
		(state,) => ({
			contacts: state.contacts,
		}),
	),
	withHandlers(() => ({
		onClickButton: ({ history, }) => () => {
			history.push('/')
		},
	})),
	withProps(({ intl, }) => ({
		title: intl.messages['not_found.page'],
		description: intl.messages['not_found.description'],
		textButton: intl.messages['not_found.link'],
	})),
)(({
	 title,
	 description,
	 textButton,
	 onClickButton,
 }) => {
	return (
		<>
			<NotFoundContentComponent
				title={title}
				description={description}
				textButton={textButton}
				onClickButton={onClickButton}
			/>
		</>
	)
}))
