import { applyMiddleware, combineReducers, createStore, } from 'redux'
import thunk from 'redux-thunk'
import compose from 'recompose/compose'
import notifications from 'react-redux-notify'
import loading from './reducers/loading'
import fonts from './reducers/fonts'
import auth from './reducers/auth'
import profile from './reducers/profile'
import message from './reducers/message'
import menu from './reducers/menu'
import contacts from './reducers/contacts'
import settings from './reducers/settings'
import modal from './reducers/modal'
import online from './reducers/online'

const composeEnhancers = typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({},) : compose

const store = createStore(combineReducers({
	loading,
	fonts,
	auth,
	profile,
	message,
	menu,
	contacts,
	settings,
	modal,
	online,
	notifications,
},), {}, composeEnhancers(applyMiddleware(thunk,),),)

export default store
