export const isPhoneValid = (phone,) => phone.replace(/\D+/g, '',).length === 11
export const isNameValid = (name,) => /^[a-zA-Zа-яА-ЯёЁ' ][a-zA-Z-а-яА-ЯёЁ' ]+[a-zA-Zа-яА-ЯёЁ']?$/.test(name,)
export const isEmailValid = (email,) => {
	// eslint-disable-next-line no-useless-escape
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email,)
		.toLowerCase(),)
}
export const isPasswordValid = (password,) => password.length > 2
export const isTextValid = (text = '',) => text && text.length > 0
