// eslint-disable-next-line no-unused-vars
import React from 'react'

export default {
	'menu.enter': 'Войти',
	'menu.create_account': 'Создать Аккаунт',
	'menu.login': 'Авторизоваться',
	'menu.search': 'Найти',
	'menu.search_button': 'Поиск',
	'menu.auctions': 'Аукционы',
	'menu.about_us': 'О нас',
	'menu.rules': 'Правила',
	'menu.contacts': 'Контакты',
	'menu.profile': 'Профиль',
	'menu.verify': 'Верификация',
	'menu.lots': 'Ваши лоты',
	'menu.favorites': 'Избранное',
	'menu.messages': 'Сообщения',
	'menu.exit': 'Выйти',

	'profile.profile_change': 'Профиль изменен!',
	'profile.avatar_change': 'Аватар изменен!',
	'profile.save_button': 'Сохранить',
	'profile.firstName': 'Имя',
	'profile.lastName': 'Фамилия',
	'profile.phone': 'Телефон',
	'profile.company': 'Компания',
	'profile.address': 'Адрес',
	'profile.choose_character': 'Выберите персонажа',
	'profile.choose': 'Выбрать',
	'profile.rating_text': 'рейтинг',
	'profile.status_text': 'покупок',
	'profile.role_text': 'продаж',

	'verify.message_ok': 'Заявка на верификацию принята!',
	'verify.message_send': 'Пройди верификацию и участвую в аукционе',
	'verify.message_email': 'Для подтверждения адреса электронной почты, на Ваш e-mail будет отправлено письмо. Для того, чтобы отправить письмо нажмите на кнопку.',
	'verify.email': 'Email',
	'verify.password': 'Пароль',
	'verify.firstName': 'Имя',
	'verify.lastName': 'Фамилия',
	'verify.phone': 'Телефон',
	'verify.company': 'Компания',
	'verify.index': 'Индекс',
	'verify.country': 'Страна',
	'verify.city': 'Город',
	'verify.address': 'Адрес',
	'verify.shtat': 'Штат (Регион)',
	'verify.random_text': 'текстовое до 300 знаков',
	'verify.passport_up': 'Лицевая сторона паспорта',
	'verify.passport_down': 'Сторона прописки',
	'verify.address_delivery': 'Адрес доставки',
	'verify.message': 'Сообщение',
	'verify.form': 'Форма загрузки документов',
	'verify.send': 'Отправить на проверку',
	'verify.thanks': 'Спасибо, ваша заявка принята! Мы свяжемся с вами в ближайшее время',
	'verify.register': 'Регистрация',
	'verify.forgot': 'Забыли пароль?',
	'verify.back': 'Назад',
	'verify.agree': 'Согласен',
	'verify.rules': 'Правила участия в аукционе',
	'verify.change': 'Изменить',
	'verify.repeat': 'Повторить пароль',
	'verify.sendCode': 'Отправить',

	'user_chats.not_found': 'В данный момент у вас нет сообщений',
	'user_lots.not_found': 'В данный момент у вас нет лотов',
	'user_lots.sort': 'Сортировка',
	'user_lots.search': 'Найти',

	'menu.auth': 'Авторизация',
	'menu.register': 'Регистрация',
	'menu.forgot': 'Восстановление пароля',

	'not_found.page': 'Страница не найдена',
	'not_found.description': 'Server Not Found error message is a Hypertext Transfer Protocol standard response code, in computer network communications',
	'not_found.link': 'На главную страницу',

	'main.auction': 'Аукционный дом Пугачевых',
	'main.future': 'Приближающиеся аукционы',
	'main.past': 'Прошедшие аукционы',
	'main.past_auction': 'Прошедший Аукцион',
	'main.popular': 'Популярные лоты',
	'main.online': 'Войти в аукцион',
	'main.results': 'Результаты всех аукционов ',

	'contacts.mark': 'Марки',
	'contacts.mark_description': 'Филателисты – коллекционеры марок. В мире собиратели марок являются самыми рьяными покупателями. Понимая это, наш аукцион предлагает купить марки коллекционные, которые придутся вам по душе. Увлеченные этим делом люди, активно собирают и ищут марки. Особый интерес вызывают редкие коллекционные марки, купить которые желает каждый уважающий себя коллекционер. \n Часть бумажек с ребристыми краями выпущена ограниченным тиражом. Такие экземпляры заслуживают особого внимания и стоят дорого. Предлагаем вашему вниманию достойные коллекции. ',

	'rules.title': 'Правила участия в аукционе',
	'rules.desc': 'Общие положения',

	'about.title': 'О Нас',
	'result.title': 'Всего результатов',

	'lots.catalog': 'Каталог лотов',

	'bids.start': 'Стартовая цена',
	'bids.up': 'Ваш высший бид',
	'bids.down': 'Ставка перебита',
	'bids.leader': 'Лидирующая ставка',
	'sort.lot': 'По лоту',
	'sort.price': 'По цене',
	'sort.name': 'По имени',
	'sort.all': 'Все',

	'lot.create_bid': 'Сделать ставку',
	'lot.start': 'Стартовая цена: ',
	'lot.not_sold': 'Не продано',
	'lot.sold': 'Продан за',
	'lot.max_bid': 'Ваша максимальная ставка:',
	'lot.max_price': 'Цена с учетом комиссии',

	'auctions.archive_title': 'Архив',
	'auctions.auctions_title': 'Аукционы',

	'online.create_bid': 'Сделать ставку',
	'online.last_chance': 'Последняя возможность!',
	'online.new_price': 'Предложенная цена',
	'online.its_yours': 'Это ваша ставка',
	'online.stop': 'Аукцион завершен',
	'online.confirm': 'Подтверждение ставки',
	'online.wait': 'Подождите',
	'online.catalog': 'Каталог',
	'online.admin': 'Администратор',
	'online.exit': 'Выход',
	'online.enter_text': 'Введите сообщение для отправки ...',
	'online.you': 'Вы',
	'online.leader': 'Лидирующая ставка',
	'online.congratulation': 'Поздравляем!',
	'online.stop_message': 'Аукцион завершен. Хорошие покупки, вы их можете посмотреть в личном кабинете.',
	'online.my_sale': 'Мои покупки',

	'chat.admin': 'Сообщения администрации',
	'chat.new': 'По новизне',
	'chat.old': 'От более старых',

	'footer.subscribe': 'Подписаться на новости',
	'footer.email': 'Ваш email',
	'footer.description': '«Аукционный дом Пугачевых» – название акции, проводимой в соответствии с Пользовательским соглашением, размещенным на данном сайте.',
}
