// eslint-disable-next-line no-unused-vars
import React from 'react'
import { connect, } from 'react-redux'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import withState from 'recompose/withState'
import FormComponent from 'auction.core/components/FormComponent'
import { injectIntl, } from 'react-intl'
import { actionForgotPassword, actionSetCode, actionSetPassword, } from '../../api/users/requests'
import { onSetToken, } from '../../actions/authActions'
import authService from '../../services/authService'
import { onSetMessage, } from '../../actions/messagesActions'
import { getProfile, } from '../../actions/profileActions'
import { sendNotifyError, sendNotifySuccess, } from '../../actions/notifyActions'

export default injectIntl(compose(
	connect(
		null,
		{
			getCurrentProfile: getProfile,
			setToken: onSetToken,
			setMessage: onSetMessage,
			onSendNotifyError: sendNotifyError,
			onSendNotifySuccess: sendNotifySuccess,
		},
	),
	withState('step', 'updateStep', 1),
	withState('email', 'updateEmail', ''),
	withState('temporary', 'updateTemporary', ''),
	withHandlers({
		onValidate: () => (fields,) => authService.validateForgot(fields,),
		onSubmitEmail: ({
			 updateEmail,
			 updateTemporary,
			 updateStep,
			onSendNotifyError,
		 },) => async (fields,) => {
			try {
				const data = await actionForgotPassword({
					email: fields.email,
				},)
				updateEmail(fields.email)
				updateTemporary(data.temporary)
				updateStep(2)
			} catch (error) {
				console.log(error,)
				// eslint-disable-next-line no-alert
				onSendNotifyError(`Произошла ошибка: ${error.text}`,)
			}
		},
		onSubmitCode: ({
			 updateStep,
			 temporary,
			onSendNotifyError,
		 },) => async (fields,) => {
			try {
				await actionSetCode({
					temporary,
					// eslint-disable-next-line radix
					code: parseInt(fields.code),
				},)
				updateStep(3)
			} catch (error) {
				console.log(error,)
				// eslint-disable-next-line no-alert
				onSendNotifyError(`Произошла ошибка: ${error.text}`,)
			}
		},
		onSubmitPassword: ({
			 temporary,
			 onCloseModal,
			onSendNotifyError,
			onSendNotifySuccess,
		 },) => async (fields,) => {
			try {
				await actionSetPassword({
					password: fields.password,
					repeat_password: fields.repeatPassword,
					temporary,
				},)
				onCloseModal()
				// eslint-disable-next-line no-alert
				onSendNotifySuccess('Пароль изменен',)
			} catch (error) {
				console.log(error,)
				// eslint-disable-next-line no-alert
				onSendNotifyError(`Произошла ошибка: ${error.text}`,)
			}
		},
	},),
	withHandlers({
		onValidate: ({ step, }) => (fields,) => {
			if (step === 1) return authService.validateForgot(fields,)
			if (step === 2) return authService.validateCode(fields,)
			if (step === 3) return authService.validatePassword(fields,)
			return true
		},
		onSubmit: ({
			 onSubmitEmail,
			 onSubmitCode,
			 onSubmitPassword,
			 step,
		 },) => async (fields,) => {
			if (step === 1) return onSubmitEmail(fields)
			if (step === 2) return onSubmitCode(fields)
			if (step === 3) return onSubmitPassword(fields)
			return null
		},
	},),
	withProps((props) => {
		if (props.step === 1) {
			return {
				buttonText: props.intl.messages['verify.sendCode'],
				form: {
					email: {
						id: 1,
						name: 'email',
						label: 'Email',
						type: 'email',
						disabled: false,
					},
				},
				formList: ['email', ],
			}
		}
		if (props.step === 2) {
			return {
				buttonText: props.intl.messages['verify.sendCode'],
				form: {
					code: {
						id: 1,
						name: 'code',
						label: 'Code',
						type: 'number',
						disabled: false,
					},
				},
				formList: ['code', ],
			}
		}
		if (props.step === 3) {
			return {
				buttonText: props.intl.messages['verify.change'],
				form: {
					password: {
						id: 1,
						name: 'password',
						label: props.intl.messages['verify.password'],
						type: 'password',
						disabled: false,
					},
					repeatPassword: {
						id: 2,
						name: 'repeatPassword',
						label: props.intl.messages['verify.repeat'],
						type: 'password',
						disabled: false,
					},
				},
				formList: ['password', 'repeatPassword', ],
			}
		}
		return {}
	},),
)(FormComponent,))
