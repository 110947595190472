import {
	isEmailValid,
	isNameValid,
	isPasswordValid,
	isTextValid,
} from '../utilits/validationInputs'

export default {
	validateRegister: ({
											 firstName,
											 lastName,
											 nickName,
											 email,
											 password,
										 },) => {
		const errors = {}

		if (!isNameValid(firstName,)) {
			errors.firstName = 'Поле должно быть заполнено.'
		}

		if (!isNameValid(lastName,)) {
			errors.lastName = 'Поле должно быть заполнено.'
		}

		if (!isNameValid(nickName,)) {
			errors.nickName = 'Поле должно быть заполнено.'
		}

		if (!isEmailValid(email,)) {
			errors.email = 'Неправильный формат адреса электронной почты.'
		}

		if (!isPasswordValid(password,)) {
			errors.password = 'Пароль должен быть длиинной не менее 8 символов.'
		}

		return errors
	},
	validateLogin: ({
		email,
		password,
	},) => {
		const errors = {}

		if (!isEmailValid(email,)) {
			errors.email = 'Неправильный формат адреса электронной почты.'
		}

		if (!isPasswordValid(password,)) {
			errors.password = 'Пароль должен быть длиинной не менее 8 символов.'
		}

		return errors
	},
	validateForgot: ({
		email,
	},) => {
		const errors = {}

		if (!isEmailValid(email,)) {
			errors.email = 'Неправильный формат адреса электронной почты.'
		}

		return errors
	},
	validatePassword: ({
		 password,
		 repeatPassword,
	},) => {
		const errors = {}

		if (!isPasswordValid(password,)) {
			errors.password = 'Пароль должен быть длиинной не менее 8 символов.'
		}
		console.log(password !== repeatPassword, password, repeatPassword)
		if (password !== repeatPassword) {
			errors.password = 'Пароли должны совпадать'
		}

		return errors
	},
	validateCode: () => {
		const errors = {}

		return errors
	},
	validateLotPrice: ({
											 price,
										 }, item,) => {
		const errors = {}

		if (price < item.started_price) {
			errors.price = 'Сумма не должна быть ниже начальной цены'
			return errors
		}

		if (price < item.price) {
			errors.price = 'Сумма не должна быть ниже предыдущей ставки'
			return errors
		}

		return errors
	},
	validateProfile: ({
		// eslint-disable-next-line camelcase
		first_name = '',
		// eslint-disable-next-line camelcase
		last_name = '',
		phone = '',
		company = '',
		address = '',
	},) => {
		const errors = {}

		if (!isTextValid(first_name,)) {
			errors.first_name = 'Поле не должно быть пустым'
		}
		if (!isTextValid(last_name,)) {
			errors.last_name = 'Поле не должно быть пустым'
		}
		if (!isTextValid(phone,)) {
			errors.phone = 'Поле не должно быть пустым'
		}
		if (!isTextValid(company,)) {
			errors.company = 'Поле не должно быть пустым'
		}
		if (!isTextValid(address,)) {
			errors.address = 'Поле не должно быть пустым'
		}

		return errors
	},
	validateVerify: ({
										 // eslint-disable-next-line camelcase
										 first_name,
										 // eslint-disable-next-line camelcase
										 last_name,
										 phone,
										 company,
										 address,
										 // eslint-disable-next-line camelcase
										 address_index,
										 // eslint-disable-next-line camelcase
										 delivery_country,
										 // eslint-disable-next-line camelcase
										 delivery_city,
										 // eslint-disable-next-line camelcase
										 delivery_street,
										 // eslint-disable-next-line camelcase
										 delivery_state,
										 // eslint-disable-next-line camelcase
										 delivery_index,
										 message,
										 capcha,
									 },) => {
		const errors = {}

		if (!isTextValid(first_name,)) {
			errors.first_name = 'Поле не должно быть пустым'
		}
		if (!isTextValid(last_name,)) {
			errors.last_name = 'Поле не должно быть пустым'
		}
		if (!isTextValid(phone,)) {
			errors.phone = 'Поле не должно быть пустым'
		}
		if (!isTextValid(company,)) {
			errors.company = 'Поле не должно быть пустым'
		}
		if (!isTextValid(address,)) {
			errors.address = 'Поле не должно быть пустым'
		}
		if (!isTextValid(address_index,)) {
			errors.address_index = 'Поле не должно быть пустым'
		}
		if (!isTextValid(delivery_country,)) {
			errors.delivery_country = 'Поле не должно быть пустым'
		}
		if (!isTextValid(delivery_city,)) {
			errors.delivery_city = 'Поле не должно быть пустым'
		}
		if (!isTextValid(delivery_street,)) {
			errors.delivery_street = 'Поле не должно быть пустым'
		}
		if (!isTextValid(delivery_state,)) {
			errors.delivery_state = 'Поле не должно быть пустым'
		}
		if (!isTextValid(delivery_index,)) {
			errors.delivery_index = 'Поле не должно быть пустым'
		}
		if (!isTextValid(capcha,)) {
			errors.capcha = 'Активируйте капчу'
		}

		return errors
	},
}
