import { gql } from 'apollo-boost'

export const VERIFICATOR_CREATE_QUERY = gql`
    mutation GetVerificationUsers(
			 $user_id: Int!
			 $first_name: String!
			 $last_name: String!
			 $phone: String!
			 $address: String!
			 $address_index: String!
			 $delivery_country: String!
			 $delivery_city: String!
			 $delivery_street: String!
			 $delivery_state: String!
			 $delivery_index: String!
			 $message: String!
			 $passport_up: Upload!
			 $passport_down: Upload!
		) {
        sendAccountToVerification(form: {
					user_id: $user_id,
					first_name: $first_name,
					last_name: $last_name,
					phone: $phone,
					address: $address,
					address_index: $address_index,
					delivery_country: $delivery_country,
					delivery_city: $delivery_city,
					delivery_street: $delivery_street,
					delivery_state: $delivery_state,
					delivery_index: $delivery_index,
					message: $message,
					passport_up: $passport_up,
					passport_down: $passport_down,
				}) {
            status
        }
    }
`
