import _defineProperty from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

var withFields = function withFields() {
  return compose(withState('fields', 'setField', function (_ref) {
    var form = _ref.form;
    return Object.keys(form).reduce(function (common, name) {
      return _objectSpread(_objectSpread({}, common), {}, _defineProperty({}, name, form[name].value || ''));
    }, {});
  }), withState('errors', 'setFieldError', function (_ref2) {
    var form = _ref2.form;
    return Object.keys(form).reduce(function (common, _ref3) {
      var name = _ref3.name;
      return _objectSpread(_objectSpread({}, common), {}, _defineProperty({}, name, ''));
    }, {});
  }), withState('error', 'setError', ''), withHandlers({
    onChangeField: function onChangeField(_ref4) {
      var fields = _ref4.fields,
          errors = _ref4.errors,
          error = _ref4.error,
          setField = _ref4.setField,
          setFieldError = _ref4.setFieldError,
          setError = _ref4.setError;
      return function (name, value) {
        setField(_objectSpread(_objectSpread({}, fields), {}, _defineProperty({}, name, value)));

        if (errors[name]) {
          setFieldError(_objectSpread(_objectSpread({}, errors), {}, _defineProperty({}, name, '')));
        }

        if (error) setError('');
      };
    }
  }));
};

export default withFields;