import storageHelper from '../../helpers/storageHelper'

export const ActionTypes = {
	SET_ONLINE: 'SET_ONLINE',
	REMOVE_ONLINE: 'REMOVE_ONLINE',
}

export const setOnline = (auctionId,) => ({
	type: ActionTypes.SET_ONLINE,
	auctionId,
})

export const removeOnline = () => ({
	type: ActionTypes.REMOVE_ONLINE,
})

const defaultUser = {
	auctionId: storageHelper.get('__online_id__', 'cookie') || '',
}

export default function onlineReducer (state = defaultUser, action,) {
	switch (action.type) {
	case ActionTypes.SET_ONLINE:
		return {
			...state,
			auctionId: action.auctionId,
		}
	case ActionTypes.REMOVE_ONLINE:
		return {
			...state,
			auctionId: '',
		}
	default: return state
	}
}
