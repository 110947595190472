export const ActionTypes = {
	STOP_APP_LOADING: 'STOP_APP_LOADING',
	START_LOADING: 'START_LOADING',
	STOP_LOADING: 'STOP_LOADING',
}

export const startLoading = () => ({
	type: ActionTypes.START_LOADING,
})

export const stopLoading = () => ({
	type: ActionTypes.STOP_LOADING,
})

export const stopAppLoading = () => ({
	type: ActionTypes.STOP_APP_LOADING,
})

const defaultLoading = {
	isAppLoading: true,
	isLoading: false,
}

export default function loadingReducer (state = defaultLoading, action,) {
	switch (action.type) {
	case ActionTypes.START_LOADING:
		return {
			...state,
			isLoading: true,
		}
	case ActionTypes.STOP_LOADING:
		return {
			...state,
			isLoading: false,
		}
	case ActionTypes.STOP_APP_LOADING:
		return {
			...state,
			isAppLoading: false,
		}
	default: return state
	}
}
