// eslint-disable-next-line no-unused-vars
import React from 'react'
import { connect, } from 'react-redux'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import FormComponent from 'auction.core/assets/components/form/FormComponent'
import withState from 'recompose/withState'
import MiniRulesContentComponent
	from 'auction.core/assets/components/miniRules/MiniRulesContentComponent'
import { injectIntl, } from 'react-intl'
import { withRouter, } from 'react-router-dom'
import { actionAuth, actionRegistration, } from '../../api/users/requests'
import authService from '../../services/authService'
import { onSetToken, } from '../../actions/authActions'
import { onSetMessage, } from '../../actions/messagesActions'
import { getProfile, } from '../../actions/profileActions'
import { sendNotifyError, } from '../../actions/notifyActions'

export default injectIntl(withRouter(compose(
	connect(
		(state,) => ({
			contacts: state.contacts,
		}),
		{
			getCurrentProfile: getProfile,
			setToken: onSetToken,
			setMessage: onSetMessage,
			onSendNotifyError: sendNotifyError,
		},
	),
	withState('fields', 'saveFields', {
		first_name: '',
		last_name: '',
		email: '',
		password: '',
	},),
	withState('page', 'updatePage', 1,),
	withHandlers({
		onValidate: () => (fields,) => authService.validateRegister(fields,),
		onSubmit: ({ saveFields, updatePage, }) => (fields) => {
			saveFields(fields)
			updatePage(2)
		},
		onPreview: ({ updatePage, }) => () => {
			updatePage(1)
		},
		onSave: ({
			setToken,
			getCurrentProfile,
			onCloseModalRegister,
		  onSendNotifyError,
			fields,
			history,
		},) => async () => {
			try {
				await actionRegistration({
					first_name: fields.first_name,
					last_name: fields.last_name,
					email: fields.email,
					password: fields.password,
				},)
				const { token, } = await actionAuth({
					email: fields.email,
					password: fields.password,
				},)
				setToken(token,)
				onCloseModalRegister()
				setTimeout(() => {
					getCurrentProfile()
				}, 1200,)
				setTimeout(() => {
					history.push('/user/verify')
				}, 1500,)
			} catch (error) {
				console.log(error,)
				// eslint-disable-next-line no-alert
				onSendNotifyError(`Произошла ошибка: ${error.text}`,)
			}
		},
	},),
	withProps(({ fields, intl, }) => ({
		buttonText: intl.messages['verify.register'],
		previewText: intl.messages['verify.back'],
		submitText: intl.messages['verify.agree'],
		chapter: intl.messages['verify.rules'],
		form: {
			first_name: {
				id: 1,
				name: 'first_name',
				value: fields.first_name,
				label: intl.messages['verify.firstName'],
				type: 'text',
			},
			last_name: {
				id: 2,
				value: fields.last_name,
				name: 'last_name',
				label: intl.messages['verify.lastName'],
				type: 'text',
			},
			email: {
				id: 4,
				value: fields.email,
				name: 'email',
				label: intl.messages['verify.email'],
				type: 'email',
			},
			password: {
				id: 5,
				value: fields.password,
				name: 'password',
				label: intl.messages['verify.password'],
				type: 'password',
			},
		},
		formList: [
			'first_name',
			'last_name',
			'email',
			'password',
		],
	}),),
)(({
	fields,
	rulesButtonText,
	chapter,
	contacts,
	previewText,
	submitText,
	onSave,
	onPreview,
	page,
	...props
}) => (
	<>
		{page === 2 && (
			<>
				<MiniRulesContentComponent
					chapter={chapter}
					rules={contacts.rules || ''}
					previewText={previewText}
					submitText={submitText}
					onSubmit={onSave}
					onPreview={onPreview}
				/>
			</>
		)}
		{page === 1 && <FormComponent {...props} />}
	</>
),)))
