export var ICON_CHEVRON_DOWN = 'chevron_down';
export var ICON_CHEVRON_RIGHT = 'chevron_right';
export var ICON_CHEVRON_LEFT = 'chevron_left';
export var ICON_SEARCH = 'search';
export var ICON_LIKE = 'like';
export var ICON_PHONE = 'phone';
export var ICON_EMAIL = 'email';
export var ICON_PHOTO = 'photo';
export var ICON_CLOSE = 'close';
export var PDF_CLOSE = 'pdf';
export var ICON_EXIT = 'exit';
export var ICON_FULLSCREEN = 'fullscreen';
export var ICON_INFO = 'info';
export var ICON_LOADING = 'loading';
export var ICON_EMOJI = 'emoji';