// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { useQuery, } from '@apollo/react-hooks'
import PageWrapperComponent from 'auction.core/components/PageWrapperComponent'
import AuctionHeaderComponent
	from 'auction.core/assets/components/auctions/auctionHeader/AuctionHeaderComponent'
import HistoryComponent from 'auction.core/assets/components/main/history/HistoryComponent'
import EmptyTextElement from 'auction.core/assets/elements/emptyText/EmptyTextElement'
import { injectIntl, } from 'react-intl'
import fileUrl from '../../../utilits/fileUrl'
import { ABOUT_US_QUERY, } from '../../../api/main/requests'
import Background from '../auctions/images/background.jpg'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
const AboutUsPageContainer = injectIntl(({
	intl,
	histories,
}) => {
	return (
		<PageWrapperComponent>
			<AuctionHeaderComponent
				title={intl.messages['about.title']}
				background={Background}
				isBig={false}
			/>
			<EmptyTextElement items={(histories || [])} />
			<HistoryComponent isInner historyItems={(histories || []).map((item, index) => {
				return ({
					...item,
					title: intl.locale === 'ru' ? item.title : item.titleEng,
					description: intl.locale === 'ru' ? item.description : item.descriptionEng,
					info: intl.locale === 'ru' ? item.info : item.infoEng,
					side: index % 2 ? 'left' : 'right',
					image: fileUrl(item.imageUrl),
					icon: fileUrl(item.iconUrl),
				})
			})} />
		</PageWrapperComponent>
	)
})
/* eslint-enable */

export default () => {
	const { loading, data, error, } = useQuery(ABOUT_US_QUERY)

	if (loading || !data) return <div className='loading' />
	if (error) return <p>Error :(</p>

	return (
		<AboutUsPageContainer
			item={data.item}
			histories={data.itemHistories}
		/>
	)
}
