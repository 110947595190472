// @flow
// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import compose from 'recompose/compose'
import { useQuery, } from '@apollo/react-hooks'
import { connect, } from 'react-redux'
import * as queryString from 'query-string'
import UserWrapperComponent from 'auction.core/assets/components/user/userWrapper/UserWrapperComponent'
import UserMenuComponent from 'auction.core/assets/components/user/userMenu/UserMenuComponent'
import LoadingElement from 'auction.core/assets/elements/loading/LoadingElement'
import UserLotsArchiveComponent from 'auction.core/assets/components/user/userLotsArchive/UserLotsArchiveComponent'
import EmptyTextElement from 'auction.core/assets/elements/emptyText/EmptyTextElement'
import { injectIntl, } from 'react-intl'
import { USER_LOTS_QUERY, } from '../../../api/auctions/requests'
import fileUrl from '../../../utilits/fileUrl'
import { getMenuSelector, } from '../../../selectors/menuSelectors'

export default compose(
	connect(
		state => ({
			items: getMenuSelector(state),
			profile: state.profile,
			token: state.auth.token,
		}),
	),
)(injectIntl(({ location, history, profile, items, intl, ...props }) => {
	// eslint-disable-next-line max-len
	const isProfileEmpty = Object.entries(profile).length === 0 && profile.constructor === Object
	const [search, updateSearch, ] = useState(queryString.parse(location.search).search,)
	const [page, updatePage, ] = useState(1,)
	const [pageSize, ] = useState(24,)
	const { isLoading, data, } = useQuery(USER_LOTS_QUERY,
		{
			variables: {
				from: (page - 1) * pageSize,
				size: pageSize,
				search: queryString.parse(location.search).search || '',
			},
		},)

	const onClickSearch = (input) => {
		history.push({
			pathname: '/user/lots',
			search: `?search=${input}`,
		})
	}

	const onUpdateSearch = (input) => {
		updateSearch(input)
	}

	if (!(isProfileEmpty || isLoading || !data)) {
		console.log((data.content.items || []))
	}
	return (
		<div style={{ backgroundColor: 'rgba(225, 225, 225, 0.37)', minHeight: '100%', }}>
			<UserWrapperComponent>
				<UserMenuComponent
					items={items.map((item) => ({ ...item, text: intl.messages[item.text], }))}
				/>
				{(isProfileEmpty || isLoading || !data) && <LoadingElement />}
				{!(isProfileEmpty || isLoading || !data) && (
					<>
						<EmptyTextElement text={intl.messages['user_lots.not_found']} items={(data.content.items || [])} />
						{(data.content.items || []).length > 0 && (
							<UserLotsArchiveComponent
								{...props}
								items={(data.content.items || []).map(({
									id,
									auctionId,
									lot,
									name,
									description,
									gallery,
								}) => ({
									to: `/item/${id}`,
									title: name,
									description,
									img: fileUrl(gallery[0] || 'logoAuction.png'),
									id,
								}))}
								title={intl.messages['user_lots.sort']}
								buttonText={intl.messages['user_lots.search']}
								search={search}
								onChangeSearch={onUpdateSearch}
								onClickFound={onClickSearch}
								totalCount={data.content.totalCount}
								pageSize={pageSize}
								page={page}
								onChangePagination={updatePage}
							/>
						)}
					</>
				)}
			</UserWrapperComponent>
		</div>
	)
}))
