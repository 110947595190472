import {
	actionGetProfile,
	actionUpdateAvatar,
	actionUpdateProfile,
	actionUpdateProfileSkinId,
} from '../api/users/requests'
import { removeProfile, setProfile, } from '../store/reducers/profile'

export const getProfile = () => async (dispatch, getState,) => {
	const { token, } = getState().auth
	try {
		const data = await actionGetProfile(token,)
		await dispatch(setProfile(data,),)
	} catch (error) {
		console.log(error,)
	}
}

export const onRemoveProfile = () => async (dispatch,) => {
	dispatch(removeProfile(),)
}

// eslint-disable-next-line consistent-return
export const updateUserAvatar = (form,) => async (dispatch, getState,) => {
	const { token, } = getState().auth
	try {
		const profile = await actionUpdateAvatar(form, token,)
		await dispatch(setProfile(profile,),)
		return null
	} catch (error) {
		if (error) {
			console.log(error,)
			return error.text
		}
	}
}

// eslint-disable-next-line consistent-return
export const updateUserSkinId = (skinId,) => async (dispatch, getState,) => {
	const { token, } = getState().auth
	try {
		const profile = await actionUpdateProfileSkinId({ skin_id: skinId, }, token,)
		await dispatch(setProfile(profile,),)
		return null
	} catch (error) {
		if (error) {
			console.log(error,)
			return error.text
		}
	}
}

// eslint-disable-next-line consistent-return
export const updateUserProfile = (fields,) => async (dispatch, getState,) => {
	const { token, } = getState().auth
	try {
		const profile = await actionUpdateProfile(fields, token,)
		await dispatch(setProfile(profile,),)
		return null
	} catch (error) {
		if (error) {
			console.log(error,)
			return error.text
		}
	}
}
