/* eslint-disable */
import React, { useEffect, useRef, } from 'react'
import cn from 'classnames'
import KurentoUtils from '../../../utilits/kurento'
import usePreviousStatus from '../../../hoc/usePreviousStatus'
import { webRTCLink,} from '../../../utilits/envConst'
import styles from './videoStreamer.module.css'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { sendNotifyError, } from '../../../actions/notifyActions'


let webRtcPeer = null
let ws = null
let wsConnections = 0

/* eslint-enable */
export default compose(
	connect(
		null,
		{
			sendNotifyMessageError: sendNotifyError,
		},
	),
)(({
	className,
	sessionId,
	sendNotifyMessageError,
}) => {
	const $videoNode = useRef(0)

	const prevSessionId = usePreviousStatus(sessionId)

	const dispose = () => {
		if (webRtcPeer) {
			webRtcPeer.dispose()
			webRtcPeer = null
		}
	}

	const viewerResponse = (message,) => {
		if (message.response !== 'accepted') {
			const errorMsg = message.message ? message.message : 'Unknow error'
			console.warn(`Call not accepted for the following reason: ${errorMsg}`,)
			dispose()
		} else {
			webRtcPeer.processAnswer(message.sdpAnswer,)
		}
	}

	const onCreateWSConnection = () => new Promise((resolve, reject) => {
		ws = new WebSocket(webRTCLink)
		window.onbeforeunload = () => {
			ws.close()
		}
		ws.onmessage = (message,) => {
			const parsedMessage = JSON.parse(message.data,)
			console.info(`Received message: ${message.data}`,)

			switch (parsedMessage.id) {
			case 'viewerResponse':
				viewerResponse(parsedMessage,)
				break
			case 'stopCommunication':
				dispose()
				break
			case 'iceCandidate':
				webRtcPeer.addIceCandidate(parsedMessage.candidate,)
				break
			default:
				console.error('Unrecognized message', parsedMessage,)
			}
		}
		ws.onerror = (e) => {
			console.log(e)
			if (wsConnections >= 5) {
				if (wsConnections === 5) sendNotifyMessageError('Соединение с сервером потеряно, перезагрузите страницу')
				setTimeout(() => {
					onCreateWSConnection()
					++wsConnections
				}, 10000)
			} else {
				setTimeout(() => {
					onCreateWSConnection()
					++wsConnections
				}, 5000)
			}
		}
	})

	useEffect(() => {
		onCreateWSConnection()
		return () => {
			dispose()
		}
	}, [])

	useEffect(() => {
		onCreateWSConnection()
		return () => {
			dispose()
		}
	}, [])

	const sendMessage = async (message,) => {
		const jsonMessage = JSON.stringify(message,)
		console.log(`Sending message: ${jsonMessage}`,)
		if (ws && ws.readyState === WebSocket.OPEN) {
			ws.send(jsonMessage,)
		} else {
			sendNotifyMessageError('Соединение с сервером потеряно, перезагрузите страницу')
		}
	}

	const onIceCandidate = async (candidate,) => {
		console.log(`Local candidate${JSON.stringify(candidate,)}`,)

		const message = {
			id: 'onIceCandidate',
			candidate,
		}
		await sendMessage(message,)
	}

	const onOfferViewer = async (error, offerSdp,) => {
		if (error) {
			console.log(error)
		} else {
			const message = {
				id: 'viewer',
				presenterId: sessionId,
				sdpOffer: offerSdp,
			}
			await sendMessage(message,)
		}
	}

	const startStreaming = () => {
		if (!webRtcPeer && ws && ws.readyState === WebSocket.OPEN) {
			const options = {
				remoteVideo: $videoNode.current,
				onicecandidate: onIceCandidate,
			}

			webRtcPeer = new KurentoUtils.WebRtcPeerRecvonly(options, (error,) => {
				if (error) {
					console.log(error)
				} else {
					webRtcPeer.generateOffer(onOfferViewer,)
				}
			},)
		}

		if (ws && !(ws.readyState === WebSocket.OPEN)) {
			setTimeout(() => { startStreaming() }, 5000)
		}
	}

	useEffect(() => {
		if (!sessionId) {
			dispose()
		} else if (sessionId !== prevSessionId) {
			dispose()
			setTimeout(() => {
				startStreaming()
			}, 500)
		}
	}, [sessionId, ])

	return (
		<video
			ref={$videoNode}
			autoPlay
			muted
			controls
			className={cn(className || '', styles.video, !sessionId && styles.videoHide)}
		/>
	)
})
