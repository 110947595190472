// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import HeaderSliderComponent from 'auction.core/components/HeaderSliderComponent'
// eslint-disable-next-line no-unused-vars
import { THEME_DARK, } from 'auction.core/providers/theme/constants'
import { useQuery, } from '@apollo/react-hooks'
import AdvantagesComponent from 'auction.core/components/AdvantagesComponent'
import MainCatalogComponent from 'auction.core/components/MainCatalogComponent'
import HistoryComponent from 'auction.core/components/HistoryComponent'
import FooterSliderComponent from 'auction.core/components/FooterSliderComponent'
import { injectIntl, } from 'react-intl'
import { ITEM_MAIN_QUERY, } from '../../../api/main/requests'
import fileUrl from '../../../utilits/fileUrl'
import PageImage from '../../../assets/page.png'

export const getDefaultCatalogData = (intl) => ({
	title: intl.messages['main.auction'],
	theme: THEME_DARK,
	items: [
		{
			to: '/auctions',
			title: intl.messages['main.future'],
			img: PageImage,
		},
		{
			to: '/archive',
			title: intl.messages['main.past'],
			img: PageImage,
		},
	],
})

const getLangAuctions = (items = [], intl) => (!items ? [] : (items || []).map((item) => ({
	...item,
	title: intl.locale === 'ru' || !item.nameEng ? item.name : item.nameEng,
	name: intl.locale === 'ru' || !item.nameEng ? item.name : item.nameEng,
	onlineText: intl.messages['main.online'],
})))

export const getArchiveCatalogData = (intl) => ({
	title: intl.messages['main.past_auction'],
	theme: THEME_DARK,
	items: [],
})

const getActiveAuction = ({
	id,
	name,
	logo,
	isStarted,
	...item
}) => ({
	...item,
	to: `/auction/${id}`,
	title: name,
	isOnline: isStarted,
	img: fileUrl(logo, 'logoLot.jpg'),
	onlineTo: `/online/${id}`,
})

const getArchiveAuction = ({
	id,
	name,
	logo,
	gallery,
	...item
}) => ({
	...item,
	to: `/auction/${id}`,
	title: name,
	img: fileUrl(logo, 'logoLot.jpg'),
})

const getPopularAuction = ({
	id,
	name,
	logo,
	gallery,
	...item
}) => ({
	...item,
	to: `/item/${id}`,
	title: name,
	imageUrl: fileUrl((gallery || [])[0], 'logoLot.jpg'),
})

const getActiveAuctions = ({ totalCount, auctions, }, intl,) => {
	if (!totalCount) return [...getDefaultCatalogData(intl).items, ]
	return [
		...getLangAuctions(auctions || [], intl,).reduce((acc, item) => {
			if (item.isFinished) return acc
			if (item.isStarted) acc.push(getActiveAuction(item))
			return acc
		}, []),
		...getDefaultCatalogData(intl).items,
		...getLangAuctions(auctions || [], intl,).reduce((acc, item) => {
			if (item.isFinished) return acc
			if (!item.isStarted) acc.push(getActiveAuction(item))
			return acc
		}, []),
	]
}

const getArchiveAuctions = (active, { totalCount, auctions, }, intl) => {
	if (!totalCount) return []
	return [
		...getLangAuctions(active.auctions || [], intl,).reduce((acc, item) => {
			if (item.isFinished) acc.push(getArchiveAuction(item))
			return acc
		}, []),
		...getLangAuctions(auctions || [], intl,).reduce((acc, item) => {
			acc.push(getArchiveAuction(item))
			return acc
		}, []),
	]
}

const getPopularAuctions = ({ totalCount, items, }, intl) => {
	if (!totalCount) return []
	return [
		...getLangAuctions(items || [], intl,).reduce((acc, item) => {
			acc.push(getPopularAuction(item))
			return acc
		}, []),
	]
}

/*eslint-disable */
const MainPageContainer = ({intl}) => {
	const { loading, data, error, } = useQuery(ITEM_MAIN_QUERY)

	if (loading || !data) return <div className='loading' />
	const auctions = getDefaultCatalogData(intl)
	const archive = getArchiveCatalogData(intl)

	auctions.items = getActiveAuctions(data.active, intl)
	archive.items = getArchiveAuctions(data.active, data.archive, intl)
	const populars = getPopularAuctions(data.populars, intl)

	const sliders = (data.itemSliders || []).reduce((acc, item) => ({
		...acc,
		[item.id]: item
	}), {})
	const advantages = (data.itemAdvantages || []).reduce((acc, item) => ({
		...acc,
		[item.id]: item
	}), {})
	const histories = (data.itemHistories || []).reduce((acc, item) => ({
		...acc,
		[item.id]: item
	}), {})

	return (
		<>
			<HeaderSliderComponent
				headerSlides={data.slidersIndexes.map((id) => ({
					...sliders[id],
					title: intl.locale === 'ru' ? sliders[id].title : sliders[id].titleEng,
					description: intl.locale === 'ru' ? sliders[id].description : sliders[id].descriptionEng,
					info: intl.locale === 'ru' ? sliders[id].info : sliders[id].infoEng,
					imageUrl: fileUrl(sliders[id].imageUrl)
				}))}
			/>
			<MainCatalogComponent
				catalog={[
					auctions,
				]}
				items={!!archive.items.length && archive}
				intl={intl}
			/>
			<AdvantagesComponent
				advantages={data.advantagesIndexes.map((id) => ({
					...advantages[id],
					title: intl.locale === 'ru' ? advantages[id].title : advantages[id].titleEng,
					description: intl.locale === 'ru' ? advantages[id].description : advantages[id].descriptionEng,
					info: intl.locale === 'ru' ? advantages[id].info : advantages[id].infoEng,
					icon: fileUrl(advantages[id].iconUrl)
				}))}
				backgroundUrl={'https://i.imgur.com/CCeRTJj.jpg'}
			/>
			<HistoryComponent historyItems={data.historiesIndexes.map((id, index) => ({
					...histories[id],
					title: intl.locale === 'ru' ? histories[id].title : histories[id].titleEng,
					description: intl.locale === 'ru' ? histories[id].description : histories[id].descriptionEng,
					info: intl.locale === 'ru' ? histories[id].info : histories[id].infoEng,
					side: index % 2 ? 'left' : 'right',
					image: fileUrl(histories[id].imageUrl),
					icon: fileUrl(histories[id].iconUrl),
				})
			)} />
			{!!populars.length && (
				<FooterSliderComponent
					title={intl.messages['main.popular']}
					footerSlides={populars}
				/>
			)}
		</>
	)
}
/* eslint-enable */

export default injectIntl(MainPageContainer)
