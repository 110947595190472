import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from 'apollo-link-ws';
import { wsLink, portLink } from '../utilits/envConst'
import { getMainDefinition } from 'apollo-utilities'
import storageHelper from '../helpers/storageHelper'

const authLink = setContext((_, { headers }) => {
	const token = storageHelper.get('__token__', 'cookie') || ''
	return {
		headers: {
			...headers,
			authorization: token
		}
	}
});
const connectionParams = () => {
	const token = storageHelper.get('__token__', 'cookie') || ''
	return token ? { 'token': token } : {};
};

const wsLinkContent = new WebSocketLink({
	uri: `${wsLink}8031/query`,
	options: {
		reconnect: true,
		connectionParams,
	}
});

let clientGQL = new ApolloClient({
	link:
		ApolloLink.split(
			({ query }) => {
				const { kind, operation } = getMainDefinition(query);
				return kind === 'OperationDefinition' && operation === 'subscription';
			},
			wsLinkContent,
			ApolloLink.from([
				onError(({ graphQLErrors, networkError }) => {
					if (graphQLErrors)
						graphQLErrors.forEach(({ message, locations, path }) =>
							console.log(
								`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
							),
						);
					if (networkError) console.log(`[Network error]: ${networkError}`);
				}),
				authLink.concat(createUploadLink({
					uri: `${portLink}8031/query`,
					credentials: 'same-origin'
				})),
			]),
		),
	cache: new InMemoryCache()
})

export default clientGQL
