export const ActionTypes = {
	SET_MODAL: 'SET_MODAL',
	CLOSE_MODAL: 'CLOSE_MODAL',
}

export const onSetModal = (name,) => ({
	type: ActionTypes.SET_MODAL,
	name,
})

export const onCloseModal = () => ({
	type: ActionTypes.CLOSE_MODAL,
})

const defaultModal = {
	visibleModal: '',
	previewModal: [],
}

export default function modalReducer (state = defaultModal, action,) {
	switch (action.type) {
	case ActionTypes.SET_MODAL:
		return {
			...state,
			visibleModal: action.name,
			previewModal: state.visibleModal ? [...state.previewModal, state.visibleModal, ] : [],
		}
	case ActionTypes.CLOSE_MODAL:
		return {
			...state,
			visibleModal: state.previewModal.length ? state.previewModal[state.previewModal.length - 1] : '',
			// eslint-disable-next-line max-len
			previewModal: state.visibleModal ? [...state.previewModal, ].splice(0, state.previewModal.length - 1) : [],
		}
	default:
		return state
	}
}
