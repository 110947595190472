// @flow
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useRef, } from 'react'
import { useMutation, useQuery, useSubscription, } from '@apollo/react-hooks'
import PageWrapperComponent from 'auction.core/components/PageWrapperComponent'
import LotHeaderComponent from 'auction.core/components/LotHeaderComponent'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import { connect, } from 'react-redux'
import {
	AuctionLastChance,
	AuctionWaitBet,
	AuctionWaitConfirmBet,
} from 'auction.core/constants/auctionStatus'
import { injectIntl, } from 'react-intl'
import {
	ACTION_STATUS_SUBSCRIPTION,
	ACTIVE_MAKE_BET_QUERY,
	ACTIVE_QUERY,
	ADD_MESSAGE_TO_CHAT, SEND_PING,
} from '../../../api/auctions/requests'
import fileUrl from '../../../utilits/fileUrl'
import { onCloseModal, onSetModal, } from '../../../store/reducers/modal'
import StartAudioElement from './sounds/start.wav'
import SetAudioElement from './sounds/set.mp3'
import BetAudioElement from './sounds/bet.wav'
import LastChanceAudioElement from './sounds/message.wav'
import usePreviousStatus from '../../../hoc/usePreviousStatus'
import VideoStreamerComponent from '../../components/video/VideoStreamerComponent'
import { onRemoveOnline, onSetOnline, } from '../../../actions/onlineActions'
import { sendNotifyError, sendNotifyInfo, } from '../../../actions/notifyActions'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
const OnlinePageContainer = injectIntl(({
	intl,
	status,
	state,
	onUpdateStatus,
	onSendMessage,
	onExit,
	onCloseFinish,
	onClickAllSales,
	onMakeBet,
	profile,
 }) => {
	const { data, } = useSubscription(ACTION_STATUS_SUBSCRIPTION,
		{
			variables: {
				auctionId: state.auction.id,
			},
		})

	useEffect(() => {
		if (!!data && !!data.content) {
			onUpdateStatus(data.content)
		}
	}, [data,])

	const items = []
	let currentIndex = null

	state.orderLots.forEach((item, index) => {
		if (!!state.reOpenStatus.currentLotId) {
			if (item.id === state.reOpenStatus.currentLotId) {
				items.push(state.allLots[item.id])
			}
			if (item.id === state.status.currentLotId) {
				currentIndex = index
			}
			if (currentIndex !== null && index >= currentIndex && index <= currentIndex + 7) {
				items.push(state.allLots[item.id])
			}
		} else {
			if (item.id === status.currentLotId) {
				currentIndex = index
			}

			if (currentIndex !== null && index >= currentIndex && index <= currentIndex + 8) {
				items.push(state.allLots[item.id])
			}
		}
	})

	return (
		<PageWrapperComponent>
			<LotHeaderComponent
				auctionName={state.auction.name}
				lot={status.currentLot}
				lotIndex={currentIndex + 1}
				total={state.orderLots.length}
				items={items}
				currentPrice={status.currentPrice}
				nextPrice={status.currentPrice + status.currentStep}
				name={intl.locale === 'ru' || !state.allLots[status.currentLotId].nameEng  ?
						state.allLots[status.currentLotId].name : state.allLots[status.currentLotId].nameEng}
				submitText={intl.messages['online.create_bid']}
				lastChanceText={intl.messages['online.last_chance']}
				titleText={intl.messages['online.new_price']}
				userBetText={intl.messages['online.its_yours']}
				finishText={intl.messages['online.stop']}
				loadingText={intl.messages['online.confirm']}
				nextText={intl.messages['online.wait']}
				catalogText={intl.messages['online.catalog']}
				adminName={intl.messages['online.admin']}
				exitTitle={intl.messages['online.exit']}
				chatPlaceholder={intl.messages['online.enter_text']}
				textCurrentUser={intl.messages['online.you']}
				textLeader={intl.messages['online.leader']}
				finishTitleText={intl.messages['online.congratulation']}
				finishDescriptionText={intl.messages['online.stop_message']}
				finishButtonText={intl.messages['online.my_sale']}
				catalogUrl={'/auction/' + state.auction.id}
				onSend={onSendMessage}
				onExit={onExit}
				messages={state.chat}
				onSubmit={onMakeBet}
				status={status.currentStatus}
				isFinished={status.isFinished}
				isUserBet={`${profile.id}` === status.leaderId}
				leaderId={status.leaderId}
				currentUserId={`${profile.id}`}
				places={state.places}
				onCloseFinish={onCloseFinish}
				onClickAllSales={onClickAllSales}
				salesItems={(state.soldLots || [])
					.filter(({
						leaderId,
					}) => `${profile.id}` === leaderId)
					.map(({
						currentLotId,
						currentPrice,
					}) => ({
						url: `/item/${currentLotId}`,
						title: intl.locale === 'ru' || !state.allLots[currentLotId].nameEng  ?
							state.allLots[currentLotId].name : state.allLots[currentLotId].nameEng,
						img: state.allLots[currentLotId].gallery[0],
						id: currentLotId,
						price: currentPrice,
					}))}
				videoBlock={<VideoStreamerComponent sessionId={state.video.sessionId}/>}
			/>
		</PageWrapperComponent>
	)
})

/* eslint-enable */
export default compose(
	connect(
		state => ({
			profile: state.profile,
			token: state.auth.token,
			onlineId: state.online.auctionId,
		}),
		{
			setModal: onSetModal,
			closeModal: onCloseModal,
			setOnline: onSetOnline,
			removeOnline: onRemoveOnline,
			sendNotifyMessageInfo: sendNotifyInfo,
			sendNotifyMessageError: sendNotifyError,
		},
	),
	withHandlers(() => ({
		onExit: ({ removeOnline, history, }) => () => {
			removeOnline()
			history.push('/')
		},
	})),
)(({
	match,
	profile,
	token,
	history,
	setModal,
	onlineId,
	setOnline,
	onExit,
	sendNotifyMessageInfo,
	sendNotifyMessageError,
}) => {
	const startAudio = new Audio(StartAudioElement)
	const setAudio = new Audio(SetAudioElement)
	const betAudio = new Audio(BetAudioElement)
	const lastChanceAudio = new Audio(LastChanceAudioElement)

	const [state, updateState, ] = useState({
		auction: {},
		status: {},
		reOpenStatus: {},
		previewStatus: {},
		users: [],
		allLots: {},
		bets: [],
		soldLots: [],
		orderLots: [],
		isLoaded: true,
		video: {
			feedId: null,
			roomId: null,
		},
		chat: [],
		places: [],
	})

	const [status, updateStatus, ] = useState(state.reOpenStatus ? state.reOpenStatus : state.status)
	const prevStatus = usePreviousStatus(status.currentStatus)

	if (!token || (
		profile.role < 3 &&
		profile.status !== 'verified')
	) {
		if (!token) {
			setModal('login')
			history.push('/')
		}
		if (profile.role < 3 && profile.status !== 'verified') history.push('/user/verify')
		// eslint-disable-next-line no-alert
		return null
	}

	const [makeBet, ] = useMutation(ACTIVE_MAKE_BET_QUERY)
	const [sendMessage, ] = useMutation(ADD_MESSAGE_TO_CHAT)
	const [setPingClient, ] = useMutation(SEND_PING)

	const sendPing = async () => {
		await setPingClient({
			variables: {
				auctionId: match.params.id,
				timestamp: (new Date()) / 1,
			},
		})
	}

	useEffect(() => {
		if (
			prevStatus === AuctionWaitConfirmBet &&
			status.currentStatus === AuctionWaitBet &&
			status.leaderId === String(profile.id)
		) {
			betAudio.play()
		}
		if (status.currentStatus === AuctionLastChance) {
			lastChanceAudio.play()
		}
	}, [status.currentStatus, ])

	useEffect(() => {
		if (state.isLoaded === false) {
			startAudio.play()
		} else {
			sendPing()
		}
	}, [state.isLoaded, ])

	useQuery(ACTIVE_QUERY,
		{
			variables: {
				auctionId: match.params.id,
			},
			onCompleted: (data) => {
				// eslint-disable-next-line max-len
				updateStatus(data.active.reOpenStatus && !!data.active.reOpenStatus.currentLotID ? data.active.reOpenStatus : data.active.status)
				updateState({
					...state,
					...data.active,
					users: data.active.users || [],
					chat: [],
					isLoaded: false,
					allLots: data.active.allLots.items.reduce((acc, item) => ({
						...acc,
						[item.id]: {
							...item,
							gallery: (item.gallery || [])
								// eslint-disable-next-line no-shadow
								.reduce((acc, url) => ([
									...acc,
									fileUrl(url, 'logoLot.jpg'),
								]), []),
						},
					}), {}),
				})
			},
		})

	const onUpdateStatus = async (data) => {
		updateState({
			...state,
			...data,
			chat: (data.chat || []).map(item => ({
				...item,
				avatar: fileUrl(item.avatar, 'avatar.png'),
				isCurrentUser: parseInt(item.userId, 10) === profile.id,
			})),
		})
		if (data.message) {
			sendNotifyMessageInfo(data.message)
		}
		updateStatus(data.reOpenStatus.currentLotId ? data.reOpenStatus : data.status)
	}

	const onMakeBet = async () => {
		setAudio.play()
		try {
			await makeBet({
				variables: {
					auctionId: match.params.id,
					bet: status.currentPrice + status.currentStep,
				},
			})
		} catch (e) {
			sendNotifyMessageError(e.message.replace('GraphQL error: ', ''))
		}
	}

	const onSendMessage = async (text) => {
		await sendMessage({
			variables: {
				auctionId: match.params.id,
				text,
			},
		})
	}

	const onCloseFinish = async () => {
		history.push('/')
	}

	const onClickAllSales = async () => {
		history.push('/user/lots')
	}

	if (state.isLoaded) return <div className='loading' />

	if (!status.isFinished && match.params.id !== onlineId) {
		setOnline(match.params.id)
	}

	return (
		<OnlinePageContainer
			state={state}
			status={status}
			profile={profile}
			onUpdateStatus={onUpdateStatus}
			onSendMessage={onSendMessage}
			onMakeBet={onMakeBet}
			onCloseFinish={onCloseFinish}
			onClickAllSales={onClickAllSales}
			onExit={onExit}
		/>
	)
})
