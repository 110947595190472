
export const getStatusContent = (status: string, role: number): string => {
	if (role === 5) return 'Ваш аккаунт самый главный, вы можете делать все, скорее всего вы владелец или администратор данного ресурса.'
	if (role === 3) return 'Ваш аккаунт позволяет выставлять лоты и управлять аукционом.'
	if (role === 4) return 'Ваша аккаунт позволяет выставлять лоты и обрабатывать информацию.'
	if (status === 'register') return 'Вы зарегистрировались, у вас есть возможность просмотра аукционов, для участия, пройдите верификацию.'
	if (status === 'verified') return 'Ваш аккаунт верифицирован, у вас есть возможность участвовать в аукционе.'
	if (status === 'awaiting') return 'Ваш аккаунт еще не верифицирован, у вас есть возможность просмотра аукционов.'
	if (status === 'rejected') return 'Ваша учетная запись отклонена. Ваши возможности ограничены.'
	return ''
}
