// @flow
import storageHelper from '../helpers/storageHelper'
import { removeOnline, setOnline, } from '../store/reducers/online'

export const onSetOnline = (auctionId: string,) => (dispatch,) => {
	storageHelper.setByExpire('__online_id__', auctionId, 1, 'cookie')
	dispatch(setOnline(auctionId,),)
}

export const onRemoveOnline = () => (dispatch,) => {
	storageHelper.remove('__online_id__', 'cookie')
	dispatch(removeOnline(),)
}
