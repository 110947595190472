// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import UserMenuComponent from 'auction.core/components/UserMenuComponent'
import compose from 'recompose/compose'
import { connect, } from 'react-redux'
import UserWrapperComponent from 'auction.core/components/UserWrapperComponent'
import UserVerifyComponent from 'auction.core/components/UserVerifyComponent'
import LoadingElement from 'auction.core/components/LoadingElement'
import BigLoadingElement from 'auction.core/components/BigLoadingElement'
import UploadTimerComponent from 'auction.core/components/UploadTimerComponent'
import withProps from 'recompose/withProps'
import lifecycle from 'recompose/lifecycle'
import { withHandlers, } from 'recompose'
import { useMutation, } from '@apollo/react-hooks'
import { injectIntl, } from 'react-intl'
import fileUrl from '../../../utilits/fileUrl'
import Default1 from './images/up-passport.svg'
import Default2 from './images/down-passport.svg'
import authService from '../../../services/authService'
import { VERIFICATOR_CREATE_QUERY, } from '../../../api/users/query'
import { getProfile, } from '../../../actions/profileActions'
import { getMenuSelector, } from '../../../selectors/menuSelectors'
import { sendNotifySuccess, } from '../../../actions/notifyActions'
// eslint-disable-next-line no-unused-vars

/*eslint-disable */
const UserVerifyPageContainer = compose(
	connect(
		state => ({
			items: getMenuSelector(state),
			profile: state.profile,
			token: state.auth.token,
		}),
		{
			getProfile,
			onSendNotifySuccess: sendNotifySuccess,
		}
	),
	lifecycle({
		componentDidMount() {
			if (!this.props.token) {
				this.props.history.push('/')
			}
			console.log(this.props.profile)
			if (this.props.profile.role !== 1 ||
				this.props.profile.status === 'verified') {
				this.props.history.push('/user/profile')
			}
		},
	}),
	withHandlers({
		onValidate: () => (fields) => authService.validateVerify(fields),
		onSubmit: ({ sendToVerified, profile, getProfile, onSendNotifySuccess, intl}) => async (fields) => {
			await sendToVerified(fields, profile.id)
			getProfile()
			onSendNotifySuccess(intl.messages['verify.message_ok'])
		},
	}),
	withProps(({ profile, intl }) => ({
		isProfileEmpty: Object.entries(profile).length === 0 && profile.constructor === Object,
		title: intl.messages['verify.message_send'],
		description: intl.messages['verify.message_email'],
		form: {
			first_name: {
				id: 'first_name',
				name: 'first_name',
				label: intl.messages['verify.firstName'],
				type: 'text',
				size: 100,
				value: profile.first_name,
			},
			last_name: {
				id: 'last_name',
				name: 'last_name',
				label: intl.messages['verify.lastName'],
				type: 'text',
				size: 100,
				value: profile.last_name,
			},
			phone: {
				id: 'phone',
				name: 'phone',
				label: intl.messages['verify.phone'],
				type: 'text',
				size: 100,
				value: profile.phone,
			},
			company: {
				id: 1,
				name: 'company',
				label: intl.messages['verify.company'],
				type: 'company',
				value: profile.company,
			},
			address: {
				id: 'address',
				name: 'address',
				label: intl.messages['verify.address'],
				type: 'text',
				size: 70,
				value: profile.address,
			},
			address_index: {
				id: 'address_index',
				name: 'address_index',
				label: intl.messages['verify.index'],
				type: 'text',
				size: 25,
			},
			delivery_country: {
				id: 'delivery_country',
				name: 'delivery_country',
				label: intl.messages['verify.country'],
				type: 'text',
				size: 35,
				value: profile.delivery_country,
			},
			delivery_city: {
				id: 'delivery_city',
				name: 'delivery_city',
				label: intl.messages['verify.city'],
				type: 'text',
				size: 60,
				value: profile.delivery_city,
			},
			delivery_street: {
				id: 'delivery_street',
				name: 'delivery_street',
				label: intl.messages['verify.address'],
				type: 'text',
				size: 40,
				value: profile.delivery_street,
			},
			delivery_state: {
				id: 'delivery_state',
				name: 'delivery_state',
				label: intl.messages['verify.shtat'],
				type: 'text',
				size: 25,
				value: profile.delivery_state,
			},
			delivery_index: {
				id: 'delivery_index',
				name: 'delivery_index',
				label: intl.messages['verify.index'],
				type: 'text',
				size: 25,
				value: profile.delivery_index,
			},
			message: {
				id: 'message',
				name: 'message',
				label: intl.messages['verify.random_text'],
				type: 'textarea',
				size: 100,
			},
			passport_up: {
				id: 'passport_up',
				name: 'passport_up',
				label: intl.messages['verify.passport_up'],
				type: 'image',
				size: 100,
				image: Default1
			},
			passport_down: {
				id: 'passport_down',
				name: 'passport_down',
				label: intl.messages['verify.passport_down'],
				type: 'image',
				size: 100,
				image: Default2
			},
			capcha: {
				id: 'capcha',
				name: 'capcha',
				label: '',
				type: 'capcha',
				size: 100,
			},
		},
		formList: [
			'first_name',
			'last_name',
			'phone',
			'company',
			[
				'address',
				'address_index',
			],
			{ type: 'line' },
			{
				type: 'text',
				text: intl.messages['verify.address_delivery'],
			},
			[
				'delivery_country',
				'delivery_city',
			],
			[
				'delivery_street',
				'delivery_state',
				'delivery_index',
			],
			{ type: 'line' },
			{
				type: 'text',
				text: intl.messages['verify.message'],
			},
			'message',
			{ type: 'line' },
			{
				type: 'text',
				text: intl.messages['verify.form'],
			},
			[
				'passport_up',
				'passport_down',
			],
			{ type: 'line' },
			'capcha',
		],
		submitText: intl.messages['verify.send'],
		timerText: intl.messages['verify.thanks'],
	}))
)(({
		 isProfileEmpty,
     isSenderLoading,
		 items,
		 title,
		 description,
		 form,
		 formList,
		 submitText,
		 timerText,
		 profile,
		 onValidate,
		 onSubmit,
		 intl,
	 }) => {

	return (
		<div style={{ backgroundColor: 'rgba(225, 225, 225, 0.37)', minHeight: '100%', }}>
			<UserWrapperComponent>
				<UserMenuComponent
					items={items.map((item) => ({ ...item, text: intl.messages[item.text], }))}
				/>
				{isSenderLoading && (<BigLoadingElement />)}
				{isProfileEmpty && <LoadingElement />}
				{!isProfileEmpty && profile.status === 'awaiting' && (
					<UploadTimerComponent text={timerText} />
				)}
				{!isProfileEmpty && profile.status === 'register' && (
					<UserVerifyComponent
						onValidate={onValidate}
						onSubmit={onSubmit}
						title={title}
						description={description}
						form={form}
						formList={formList}
						submitText={submitText}
						avatar={fileUrl(profile.avatar)}
					/>
				)}
			</UserWrapperComponent>
		</div>
	)
})
/* eslint-enable */

export default injectIntl((props,) => {
	const [
		sendToVerified,
		{ loading: isSenderLoading, },
	] = useMutation(VERIFICATOR_CREATE_QUERY,)
	const send = async (fields, userId,): void => {
		await sendToVerified({
			variables: {
				user_id: userId,
				first_name: fields.first_name,
				last_name: fields.last_name,
				phone: fields.phone,
				address: fields.address,
				address_index: fields.address_index,
				delivery_country: fields.delivery_country,
				delivery_city: fields.delivery_city,
				delivery_street: fields.delivery_street,
				delivery_state: fields.delivery_state,
				delivery_index: fields.delivery_index,
				message: fields.message,
				passport_up: fields.passport_up,
				passport_down: fields.passport_down,
			},
		},)
		return null
	}
	return (
		<UserVerifyPageContainer
			{...props}
			sendToVerified={send}
			isSenderLoading={isSenderLoading}
		/>
	)
})
