// eslint-disable-next-line standard/object-curly-even-spacing
import { connect, } from 'react-redux'
// eslint-disable-next-line standard/object-curly-even-spacing
import { withRouter, } from 'react-router-dom'
import lifecycle from 'recompose/lifecycle'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import withState from 'recompose/withState'
import withProps from 'recompose/withProps'
// eslint-disable-next-line no-unused-vars
import React, { useEffect, } from 'react'
import { useQuery, } from '@apollo/react-hooks'
import { injectIntl, } from 'react-intl'
import PageWrapper from './PageWrapperComponent'
import locationHelper from '../../../helpers/locationHelper'
// eslint-disable-next-line standard/object-curly-even-spacing
import { getProfile, onRemoveProfile, } from '../../../actions/profileActions'
import { onRemoveToken, } from '../../../actions/authActions'
import { CONTACTS_QUERY, } from '../../../api/main/requests'
import { addContacts, } from '../../../store/reducers/contacts'
import { addSettings, } from '../../../store/reducers/settings'
import { onCloseModal, onSetModal, } from '../../../store/reducers/modal'
import { getMenuSelector, } from '../../../selectors/menuSelectors'
import { getLocaleSelector, } from '../../../selectors/settingsSelectors'
import { onSetLocaleAction, } from '../../../actions/settingsActions'

export const getDefaultFooterData = ({ phone, email, copyright, }, intl) => ({
	subscribeTitle: intl.messages['footer.subscribe'],
	subscribePlaceholder: intl.messages['footer.email'],
	phone: phone || '+7 (916) 300 45 48',
	email: email || 'pugachev-auction@mail.ru',
	policy: intl.messages['footer.description'],
	copyright: copyright || `© ${(new Date()).getFullYear()}, pugachev-auction`,
	emailValue: '',
})

const menuItems = [
	{
		data: [
			{
				text: 'menu.auctions',
				to: '/auctions',
			},
			{
				text: 'menu.about_us',
				to: '/about_us',
			},
			{
				text: 'menu.rules',
				to: '/rules',
			},
			{
				text: 'menu.contacts',
				to: '/contacts',
			},
		],
	},
]

const languageItems = [
	{
		value: 'ru',
		name: 'Русский',
	},
	{
		value: 'en',
		name: 'English',
	},
]

export const getDefaultHeaderData = (items, onlineId, history, locale, intl) => ({
	homeUrl: '/',
	logoUrl: '/',
	username: '',
	loginText: intl.messages['menu.enter'],
	registerText: intl.messages['menu.create_account'],
	userAvatarUrl: '',
	profileText: intl.messages['menu.login'],
	submitText: intl.messages['menu.search'],
	userMenuList: items.map((item) => ({
		...item,
		text: intl.messages[item.text],
	})),
	languageList: languageItems,
	language: languageItems.find(({ value, }) => value === locale).name,
	form: {
		search: {
			id: 1,
			name: 'search',
			placeholder: intl.messages['menu.search_button'],
			type: 'search',
			value: '',
			inputState: 'outline',
		},
	},
	formList: [
		'search',
	],
	menuList: [{
		data: menuItems[0].data.map((item) => ({
			...item,
			text: intl.messages[item.text],
		})),
	}, ],
	// eslint-disable-next-line no-nested-ternary
	onlineUrl: onlineId ? (history.location.pathname === `/online/${onlineId}` ? null : `/online/${onlineId}`) : null,
})
const PageWrapperContainer = compose(
	connect(
		(state,) => ({
			token: state.auth.token,
			items: getMenuSelector(state),
			locale: getLocaleSelector(state),
			profile: state.profile,
			contacts: state.contacts,
			visibleModal: state.modal.visibleModal,
			previewModal: state.modal.previewModal,
			onlineId: state.online.auctionId,
		}),
		{
			getProfile,
			addContactsContent: addContacts,
			addSettingsContent: addSettings,
			onChangeLocale: onSetLocaleAction,
			logOut: onRemoveToken,
			clear: onRemoveProfile,
			setModal: onSetModal,
			closeModal: onCloseModal,
		},
	),
	withState('tick', 'changeTick', '',),
	withState('isContentVisible', 'changeContentVisible', locationHelper.headerVisible(),),
	withHandlers({
		// eslint-disable-next-line max-len
		changePathname: ({ changeContentVisible, },) => (pathname,) => changeContentVisible(locationHelper.headerVisible(pathname,),),
	},),
	lifecycle({
		componentDidMount () {
			const { history, } = this.props

			this.props.changePathname(history.location.pathname,)

			history.listen((location,) => {
				this.props.changePathname(location.pathname,)
			},)

			if (this.props.token) {
				this.props.getProfile()
			}
		},
		componentDidUpdate (prevProps,) {
			if (prevProps.token !== this.props.token && !!this.props.token) {
				this.props.getProfile()
			}
		},
		componentWillUnmount () {
			if (this.tick) {
				clearInterval(this.tick,)
			}
		},
	},),
	withHandlers(() => ({
		onOpenLogin: ({ setModal, },) => () => {
			setModal('login')
		},
		onOpenRegister: ({ setModal, },) => () => {
			setModal('register')
		},
		onOpenForgot: ({ setModal, },) => () => {
			setModal('forgot')
		},
		onCloseModal: ({ closeModal, },) => () => {
			closeModal()
		},
		onClickUser: ({ history, },) => () => {
			history.push('/user/profile',)
		},
		onClickLogOut: ({ logOut, clear, },) => () => {
			logOut()
			clear()
		},
		onClickLanguageElement: ({ onChangeLocale, },) => async (locale) => {
			onChangeLocale(locale,)
		},
		onSubmit: ({ history, },) => (data) => {
			history.push({
				pathname: '/search',
				search: `?search=${data.search}`,
			})
		},
	}),),
	withProps(({ intl, }) => ({
		loginTitle: intl.messages['menu.auth'],
		registerTitle: intl.messages['menu.register'],
		forgotTitle: intl.messages['menu.forgot'],
	}),),
	withProps(({ items, contacts, onlineId, history, profile, intl, locale, }) => ({
		companyName: 'Pugachev',
		headerContent: getDefaultHeaderData(items, onlineId, history, locale, intl,),
		footerContent: getDefaultFooterData(contacts, intl),
	}),),
)(({ addContactsContent, addSettingsContent, ...props }) => {
	const { data, } = useQuery(CONTACTS_QUERY)
	useEffect(() => {
		if (data) {
			addContactsContent(data.contacts)
			addSettingsContent(data.settings)
		}
	}, [data, ])

	return <PageWrapper {...props} />
})

export default injectIntl(withRouter(PageWrapperContainer))
