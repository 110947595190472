import api from "../api";
import * as interfaces from "./interface";

export const actionAuth = async (
    dataBody,
  
): Promise<interfaces.SuccessToken> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/profile/login',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.SuccessToken) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionSocialLogin = async (
    dataBody,
  
): Promise<interfaces.SuccessToken> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/profile/social/login',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.SuccessToken) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionSocialAdd = async (
    dataBody,
  token?: string,
): Promise<interfaces.SuccessToken> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/social/add',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.SuccessToken) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionForgotPassword = async (
    dataBody,
  
): Promise<interfaces.ForgotInterfaceResponse> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/profile/forgot',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.ForgotInterfaceResponse) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionSetCode = async (
    dataBody,
  
): Promise<interfaces.Success> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/profile/code',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.Success) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionSetPassword = async (
    dataBody,
  
): Promise<interfaces.Success> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/profile/password',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.Success) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionUpdatePassword = async (
    dataBody,
  token?: string,
): Promise<interfaces.Success> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/update/password',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.Success) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionRegistration = async (
    dataBody,
  
): Promise<interfaces.SuccessToken> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/profile/register',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.SuccessToken) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionGetProfile = async (
  token?: string,
): Promise<interfaces.ProfileInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/me',
        method: 'get',
        port: '8040',
        headers: headers,
      })
        .then((response: interfaces.ProfileInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionGetUserProfile = async (
    { user_id, },
  token?: string,
): Promise<interfaces.ProfileInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/get/:user_id',
        method: 'get',
        port: '8040',
        headers: headers,
        urlParams: {
          user_id,
        },
      })
        .then((response: interfaces.ProfileInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionUpdateProfile = async (
    dataBody,
  token?: string,
): Promise<interfaces.ProfileInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/update/profile',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.ProfileInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionUpdateProfileSkinId = async (
    { skin_id, },
  token?: string,
): Promise<interfaces.ProfileInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/update/skinId/:skin_id',
        method: 'post',
        port: '8040',
        headers: headers,
        urlParams: {
          skin_id,
        },
      })
        .then((response: interfaces.ProfileInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionUpdateAvatar = async (
    dataForm,
  token?: string,
): Promise<interfaces.ProfileInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/update/avatar',
        method: 'post',
        port: '8040',
        headers: headers,
        isForm: true,
        body: dataForm,
      })
        .then((response: interfaces.ProfileInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionUpdateBackground = async (
    dataForm,
  token?: string,
): Promise<interfaces.ProfileInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/profile/update/background',
        method: 'post',
        port: '8040',
        headers: headers,
        isForm: true,
        body: dataForm,
      })
        .then((response: interfaces.ProfileInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionGetUser = async (
    { user_id, },
  
): Promise<interfaces.UserInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/users/user/:user_id',
        method: 'get',
        port: '8040',
        headers: headers,
        urlParams: {
          user_id,
        },
      })
        .then((response: interfaces.UserInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionGetCurrentUser = async (
    { user_id, },
  token?: string,
): Promise<interfaces.UserInterface> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    if (!!token) headers["Authorization"] = `Token ${token}`;
    try {
      api({
        url: '/api/v1/users/users/current/:user_id',
        method: 'get',
        port: '8040',
        headers: headers,
        urlParams: {
          user_id,
        },
      })
        .then((response: interfaces.UserInterface) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

export const actionGetUsers = async (
    dataBody,
  
): Promise<interfaces.UsersList> => {

  return new Promise((resolve, reject) => {
    let headers = {};
    try {
      api({
        url: '/api/v1/users/users/all',
        method: 'post',
        port: '8040',
        headers: headers,
        body: dataBody,
      })
        .then((response: interfaces.UsersList) => {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch(error) {
      reject(error);
    }
  });
};

