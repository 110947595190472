// eslint-disable-next-line import/no-self-import
import styles from './icon.module.css';
import iconExport from './iconExport';

var createWrapper = function createWrapper(pack) {
  var svgWrapper = document.createElement('div');
  svgWrapper.innerHTML = pack;
  svgWrapper.style.display = 'none';
  return svgWrapper;
};

var injectIcons = function injectIcons(_ref) {
  var pack = _ref.pack,
      isColored = _ref.isColored;
  var monoColorWrapper = createWrapper(pack);

  if (!isColored) {
    monoColorWrapper.classList.add(styles.iconPack);
  }

  document.body.appendChild(monoColorWrapper);
};

injectIcons({
  pack: iconExport
});