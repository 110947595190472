// @flow
// eslint-disable-next-line no-unused-vars
import React from 'react'
import compose from 'recompose/compose'
import { useMutation, useQuery, } from '@apollo/react-hooks'
import { withHandlers, withProps, } from 'recompose'
import ItemHeaderComponent from 'auction.core/components/ItemHeaderComponent'
import PageWrapperComponent from 'auction.core/components/PageWrapperComponent'
import { numToPriceHelper, } from 'auction.core/helpers/numToPriceHelper'
import { connect, } from 'react-redux'
import { injectIntl, } from 'react-intl'
import {
	ADD_ITEM_PRICE_QUERY,
	ITEM_QUERY,
	SET_LOT_FAVORITE_QUERY,
} from '../../../api/auctions/requests'
import authService from '../../../services/authService'
import fileUrl from '../../../utilits/fileUrl'
import { sendNotifySuccess, } from '../../../actions/notifyActions'
import { getProfileRoot, } from '../../../selectors/profileSelectors'
// eslint-disable-next-line no-unused-vars

const getSteps = (steps, price = 0, maxRate) => {
	let currentPrice = price
	const allSteps = []

	for (let i = 1; i <= 45; i++) {
		// eslint-disable-next-line max-len,no-loop-func
		const currentStep = steps.reduce((acc, { sum, step, }) => (sum <= currentPrice && step > acc ? step : acc), 1)

		if (!maxRate || currentPrice + currentStep < maxRate) {
			currentPrice += currentStep
			allSteps.push({
				value: currentPrice,
				label: numToPriceHelper(currentPrice, '₽'),
			})
		}
	}

	return {
		value: allSteps.length ? allSteps[0].value : null,
		options: allSteps,
	}
}

const getSumm = ({ auctionId, userId, itemId, rate, isOutBid, }, currentUserId, intl,) => {
	if (!rate) return { lineText: '', color: '', summ: null, }
	if (`${currentUserId}` === `${userId}`) return { lineText: intl.messages['bids.up'], color: 'green', summ: rate, }
	if (isOutBid) return { lineText: intl.messages['bids.down'], color: 'red', summ: rate, }
	return { lineText: intl.messages['bids.leader'], color: 'blue', summ: rate, }
}
const getCurrentPrice = ({ rate, }, startedPrice,) => {
	if (!rate) return startedPrice
	return rate
}

/*eslint-disable */
const ItemPageContainer = injectIntl(compose(
	connect(
		state => ({
			profile: state.profile,
			settings: state.settings,
			token: state.auth.token,
			role: getProfileRoot(state).role,
		}),
		null
	),
	withHandlers({
		onValidate: ({ item }) => (fields) => {
			return authService.validateLotPrice(fields, item)
		},
		onSubmit: ({ savePrice }) => async (fields) => {
			await savePrice(fields)
		},
	}),
	withProps(({ item, profile, settings, intl }) => {
		const currentPrice = getCurrentPrice((item.maxBit || {}), item.started_price,)
		return ({
			submitText: intl.messages['lot.create_bid'],
			startedPriceText: intl.messages['lot.start'],
			endedText: !item.status ? '' : (!item.ended_price ? intl.messages['lot.not_sold'] : intl.messages['lot.sold']),
			form: {
				id: 1,
				label: intl.messages['lot.max_bid'],
				...getSteps(item.steps, currentPrice, item.maxRate),
			},
			commissionPriceText: intl.messages['lot.max_price'],
			commissionPrice: (currentPrice + ((currentPrice / 100) * (settings.commission || 1))),
			startedTimeText: '',
			...getSumm((item.maxBit || {}), profile.id, intl, ),
			formList: [
				'price',
			],
			images: (item.gallery || [])
		})
	})
)(({
		 item,
		 submitText,
		 startedPriceText,
		 endedText,
		 form,
		 formList,
		 token,
		 role,
		 images,
		 onValidate,
		 onSubmit,
		 onSetFavorite,
		 commissionPriceText,
		 commissionPrice,
		 startedTimeText,
		 lineText,
		 color,
		 summ,
		 intl,
	 }) => {

	return (
		<PageWrapperComponent color={'#ffffff'}>
			<ItemHeaderComponent
				name={intl.locale === 'ru' || !item.nameEng  ? item.name : item.nameEng}
				lot={item.lot}
				isFavorite={item.isFavorite}
				images={images.map(image => fileUrl(image))}
				submitText={submitText}
				startedPriceText={startedPriceText}
				startedPrice={item.started_price}
				endedPrice={item.ended_price}
				endedText={endedText}
				status={item.status}
				description={intl.locale === 'ru' || !item.descriptionEng  ? item.description : item.descriptionEng}
				form={form}
				formList={formList}
				isAuth={!!token && role > 1}
				onValidate={onValidate}
				onSubmit={onSubmit}
				onSetFavorite={onSetFavorite}
				commissionPriceText={commissionPriceText}
				commissionPrice={commissionPrice}
				startedTimeText={startedTimeText}
				lineText={lineText}
				color={color}
				summ={summ}
			/>
		</PageWrapperComponent>
	)
}))
/* eslint-enable */

export default compose(
	connect(
		null,
		{
			onSendNotifySuccess: sendNotifySuccess,
		}
	)
)(({ match, onSendNotifySuccess, },) => {
	const [addPrice, ] = useMutation(ADD_ITEM_PRICE_QUERY,)
	const [setFavorite, ] = useMutation(SET_LOT_FAVORITE_QUERY,)
	const { loading, data, error, refetch, } = useQuery(ITEM_QUERY,
		{
			variables: {
				item_id: parseInt(match.params.id, 10),
			},
		},)

	const savePrice = async ({ price, },) => {
		await addPrice({
			variables: {
				auctionId: data.item.auctionId,
				itemId: parseInt(match.params.id, 10),
				price,
			},
		},)
		await refetch()
		// eslint-disable-next-line no-alert
		onSendNotifySuccess('Ставка сделана!',)
	}

	const onSetFavorite = async (isFavorite,) => {
		await setFavorite({
			variables: {
				auctionId: data.item.auctionId,
				itemId: parseInt(match.params.id, 10),
				isFavorite,
			},
		},)
		await refetch()
	}

	if (loading || !data) return <div className='loading' />
	if (error) return <p>Error :(</p>

	return (
		<ItemPageContainer
			item={data.item}
			savePrice={savePrice}
			onSetFavorite={() => onSetFavorite(!data.item.isFavorite)}
		/>
	)
})
