// eslint-disable-next-line no-unused-vars
import React from 'react'
import { connect, } from 'react-redux'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import FormComponent from 'auction.core/components/FormComponent'
import { BUTTON_TYPE_LINK, } from 'auction.core/constants/buttonTypesContants'
import { BUTTON_STATE_PRIMARY, } from 'auction.core/constants/buttonStatesContants'
import ButtonElement from 'auction.core/assets/elements/controls/button/ButtonElement'
import { injectIntl, } from 'react-intl'
import { actionAuth, } from '../../api/users/requests'
import { onSetToken, } from '../../actions/authActions'
import authService from '../../services/authService'
import { onSetMessage, } from '../../actions/messagesActions'
import { getProfile, } from '../../actions/profileActions'
import { sendNotifyError, } from '../../actions/notifyActions'

export default injectIntl(compose(
	connect(
		null,
		{
			getCurrentProfile: getProfile,
			setToken: onSetToken,
			setMessage: onSetMessage,
			onSendNotifyError: sendNotifyError,
		},
	),
	withHandlers({
		onValidate: () => (fields,) => authService.validateLogin(fields,),
		onSubmit: ({
			 setToken,
			 getCurrentProfile,
			 onCloseModalLogin,
			onSendNotifyError,
		 },) => async (fields,) => {
			try {
				const data = await actionAuth({
					email: fields.email,
					password: fields.password,
				},)
				setToken(data.token,)
				onCloseModalLogin()
				getCurrentProfile()
			} catch (error) {
				console.log(error,)
				// eslint-disable-next-line no-alert
				onSendNotifyError(`Произошла ошибка: ${error.text}`,)
			}
		},
	},),
	withProps((props) => ({
		buttonText: 'Войти',
		form: {
			email: {
				id: 1,
				name: 'email',
				label: props.intl.messages['verify.email'],
				type: 'email',
				disabled: false,
			},
			password: {
				id: 1,
				name: 'password',
				label: props.intl.messages['verify.password'],
				type: 'password',
				disabled: false,
			},
		},
		formList: ['email', 'password', ],
		innerContent: (
			<>
				<ButtonElement
					type={BUTTON_TYPE_LINK}
					state={BUTTON_STATE_PRIMARY}
					onClick={props.onOpenRegister}
				>
					{props.intl.messages['verify.register']}
				</ButtonElement>
				<ButtonElement
					type={BUTTON_TYPE_LINK}
					state={BUTTON_STATE_PRIMARY}
					onClick={props.onOpenForgot}
				>
					{props.intl.messages['verify.forgot']}
				</ButtonElement>
			</>
		),
	}),),
)(FormComponent,))
