import { gql } from 'apollo-boost'

export const ITEM_MAIN_QUERY = gql`
    query {
        itemSliders(isActive: true) {
            id,
            title,
            info,
            description,
            titleEng,
            infoEng,
            descriptionEng,
            imageUrl,
            videoUrl,
            index,
        }
				slidersIndexes: itemIndexes(table: "sliders"),
				advantagesIndexes: itemIndexes(table: "advantages"),
				historiesIndexes: itemIndexes(table: "histories", itemId: "main")
        itemAdvantages(isActive: true) {
            id,
            title,
            description,
            titleEng,
            descriptionEng,
            iconUrl,
            index,
        }
        itemHistories(isActive: true, page: "main") {
            id,
            title,
            info,
            description,
            titleEng,
            infoEng,
            descriptionEng,
            iconUrl,
            imageUrl,
            index,
        }
        active: auctionsWithoutArchive(pager: {from: 0, size: 10000}) {
            auctions{
                id,
                name,
                description,
                nameEng,
                descriptionEng,
                background,
                startedAt,
                logo,
								steps {
									sum
									step
								},
                isStarted,
                isFinished,
            }
            totalCount
        }
        archive: auctionsArchive(pager: {from: 0, size: 10000}) {
            auctions{
                id,
                name,
                description,
                nameEng,
                descriptionEng,
                background,
                logo,
            }
            totalCount
        }
        populars(pager: {from: 0, size: 10000}) {
            totalCount,
            items {
                id,
                auctionId,
                lot,
                name,
                description,
                nameEng,
                descriptionEng,
                gallery,
                started_price,
                ended_price,
            }
        }
    }
`

export const ABOUT_US_QUERY = gql`
    query {
        itemHistories(isActive: true, page: "about_us") {
            id,
            title,
            info,
            description,
            titleEng,
            infoEng,
            descriptionEng,
            iconUrl,
            imageUrl,
            index,
        }
    }
`

export const CONTACTS_QUERY = gql`
    query contacts {
        contacts {
            id,
            name,
            value,
            valueEng,
        }
				settings {
            id,
            name,
            value,
        }
    }
`
