import _defineProperty from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import cn from 'classnames';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import withProps from 'recompose/withProps';
import renderComponent from 'recompose/renderComponent';
import setDisplayName from 'recompose/setDisplayName';
import IconElement from '../../icon/IconElement';
import styles from './buttonIconElement.module.css';
import AvatarElement from '../../avatar/AvatarElement';
var ButtonIconElement = compose(setDisplayName('ButtonIconElement'), withProps(function (_ref) {
  var icon = _ref.icon;
  return typeof icon === 'string' ? {
    name: icon
  } : icon;
}), withProps(function (props) {
  var _cn;

  return {
    className: cn(styles.icon, (_cn = {}, _defineProperty(_cn, styles.before, props.isLeft), _defineProperty(_cn, styles.after, props.isRight), _defineProperty(_cn, styles.avatar, props.url), _cn))
  };
}), branch(function (props) {
  return props.url;
}, renderComponent(AvatarElement)))(IconElement);
export default ButtonIconElement;