import _objectSpread from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _slicedToArray from "/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
var transitionTime = 400;

var getPercentTransform = function getPercentTransform(index, length) {
  return index * (100 / (length + 2));
};

export function useCarousel(length, init) {
  var _useState = useState(init),
      _useState2 = _slicedToArray(_useState, 2),
      active = _useState2[0],
      updateActive = _useState2[1];

  var _useState3 = useState({
    transform: "translateX(-".concat(getPercentTransform(active, length), "%)"),
    width: "".concat(100 * (length + 2), "%"),
    left: "-".concat((active + 1) * 100, "%"),
    transition: "transform ".concat(transitionTime, "ms ease")
  }),
      _useState4 = _slicedToArray(_useState3, 2),
      style = _useState4[0],
      updateStyle = _useState4[1];

  var setActive = function setActive(index) {
    updateStyle(_objectSpread(_objectSpread({}, style), {}, {
      transform: "translateX(-".concat(getPercentTransform(index, length), "%)")
    }));
    updateActive(index);
  };

  return [active, setActive, style];
}