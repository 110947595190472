import { portLink, } from './envConst'

const getImageSize = (width,) => (width || '')

export default function fileUrl (value, defaultImage, width,) {
	if (value) {
		if (value.indexOf('http:',) >= 0 || value.indexOf('https:',) >= 0 || value.indexOf('file:',) >= 0) {
			return value
		}
		return `${portLink}8001/${getImageSize(width,)}${value}`
	}
	return `${portLink}8001/${getImageSize(width,)}${defaultImage}`
}
