import { getProfileRoot, } from './profileSelectors'

const getMenuRoot = (state,) => state.menu

export const getMenuSelector = (state) => (
	getMenuRoot(state).list.reduce((acc, item) => {
		if (item.id !== 'verify' ||
		(getProfileRoot(state).role === 1 &&
			getProfileRoot(state).status !== 'verified')) {
			acc.push(item)
		}
		return acc
	}, [])
)
